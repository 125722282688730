import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';

import { ColumnFilter } from 'src/app/common/models/request-data.model';
import { FilterService } from '../../search-filter/filter.service';
import { FilterListItemModel, FilterOperator } from '../../search-filter/search-filter.component';

@Component({
    selector: 'app-filter-item',
    templateUrl: './filter-item.component.html',
    styleUrls: ['./filter-item.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: false
})
export class FilterItemComponent implements OnInit, OnChanges {


  @Input() filter?: FilterListItemModel[];
  @Input() search: any;
  @Output() remove: EventEmitter<any> = new EventEmitter();

  selectedFilterOn?: any;

  operator: string[] = [];
  values: any[] = [];

  constructor(private filterService: FilterService) { }


  ngOnChanges(changes: SimpleChanges): void {
    if (changes['search']) {
      if (changes['search'].currentValue == undefined) {
        this.search = { column: "", operator: "", value: "", type:undefined };
        this.filterChanged(undefined);
      }
    }
  }

  ngOnInit(): void {
    if (this.filter !== undefined && this.filter.length > 0) {
      if (this.search !== undefined && this.search.column !== "") {
        this.preSetFilter(this.search);
      } else {
        this.selectedFilterOn = this.filter[0];
        this.filterChanged(undefined);
      }
    }

  }

  /**
   * Set the filters from preset values
   */
  preSetFilter(presetFilter: any): void {
    if (this.filter !== undefined) {
      let loadedFilterOn = this.filter?.find(f => f.column === presetFilter.column);
      if (loadedFilterOn !== undefined) {
        this.selectedFilterOn = loadedFilterOn;
        if (this.selectedFilterOn.operators == undefined || this.selectedFilterOn.operators.length == 0) {
          this.selectedFilterOn.operators = this.getOperators(this.selectedFilterOn.type);
        }
        this.search = presetFilter;
      }
    }
  }

  /**
   * Delete this filter
   * Remove the items from fiter array.
   */
  onDeleteClick(): void {
    this.remove.emit();
  }

  /**
   * When column filter changes update the other filters too
   * @param $event 
   */
  filterChanged($event: any): void {

    if (this.selectedFilterOn.operators == undefined || this.selectedFilterOn.operators.length == 0) {
      this.selectedFilterOn.operators = this.getOperators(this.selectedFilterOn.type);
    }
    this.search.column = this.selectedFilterOn.column;
    this.search.operator = this.selectedFilterOn.operators[0].operator;
    this.search.value = "";
    this.search.type = this.selectedFilterOn.type;

  }

  /**
   * Returns the operator if not specifically defined.
   * Uses the type of the filter and get the default operator list from the service
   * @param type data type for the filter
   * @returns 
   */
  getOperators(type: string): FilterOperator[] {
    return this.filterService.getDefultOperators(type);
  }

}
