<div class="summary-page-root-container" [style.width]="show ? '1000px' : '0px'">
    <div [ngClass]="{ 'close-btn': show, 'open-btn': !show }">
        <button mat-icon-button (click)="close()">
            @if(show){
            <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                xml:space="preserve" fill="#5F6368">
                <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                <path fill="none" d="M0,0h24v24H0V0z"></path>
            </svg>
            }@else{
            <svg x="0px" y="0px" width="20px" height="20px" viewBox="0 0 24 24" enable-background="new 0 0 24 24"
                style="transform: rotate(180deg);" xml:space="preserve" fill="#5F6368">
                <path d="M8.59,16.59L13.17,12L8.59,7.41L10,6l6,6l-6,6L8.59,16.59z"></path>
                <path fill="none" d="M0,0h24v24H0V0z"></path>
            </svg>
            }
        </button>
    </div>
    @if(show && customer !== undefined){
    <div class="page-container" style="overflow-y: auto">
        <div class="page-header">
            <div class="title">
                {{ customer.title }} {{ customer.first_name }} {{ customer.last_name }}
            </div>
        </div>

        <div class="page-content">
            <div class="section purchase-container">
                <div class="section-content purchase-container-content">
                    <div class="purchase-count purchase-content-item">
                        <span style="font-weight:500">Email</span>&nbsp;:
                        <span>{{ customer.email }}</span>
                    </div>
                    <div class="purchase-count purchase-content-item">
                        <span style="font-weight:500">Phone</span>:
                        <span class="purchase-count-value purchase-value" style="width: 114px">{{ customer.phone_number
                            }}</span>
                    </div>
                    <div class="purchase-count purchase-content-item">
                        <span style="font-weight:500">DOB&nbsp;&nbsp;&nbsp;</span>:
                        @if(customer.dob_month && customer.dob_day){
                        <span class="purchase-count-value purchase-value" style="width:160px;font-size:14px;">
                            <p>{{customer.dob_day}} {{ getMonthName(customer.dob_month) }} {{customer.dob_year}}</p>
                        </span>
                        }@else if(customer.dob_month && customer.dob_year){
                        <span class="purchase-count-value purchase-value" style="width:160px;font-size:14px;">
                            <p>{{ getMonthName(customer.dob_month) }} {{customer.dob_year}}</p>
                        </span>
                        }@else{
                        <span class="purchase-count-value purchase-value" style="width: 114px"> -
                        </span>
                        }
                    </div>
                </div>

                <div class="section note-container">
                    <mat-form-field>
                        <mat-label>Notes..</mat-label>
                        <textarea matInput placeholder="" [readonly]="!editNote" (click)="enableEditMode()"
                            [(ngModel)]="customer.notes">
            </textarea>
                    </mat-form-field>
                    <div *ngIf="editNote" class="save-note">
                        <a mat-icon-button (click)="saveNote()"><mat-icon>done</mat-icon></a>
                        <a mat-icon-button (click)="resetNote()"><mat-icon>close</mat-icon></a>
                    </div>
                </div>
                <!-- <div class="section status-container">
                <div class="section-title"></div>
                <div class="section-content ">
                    <div class="customer-type-status">
                        <div *ngIf="customer.customer_type==1" class="cust-type reg">
                            Registered
                        </div>
                        <div *ngIf="customer.customer_type!==1" class="cust-type guest">
                            Guest
                        </div>
                    </div>
                    <div class="customer-subscription-status">
                        <div *ngIf="customer.is_subscribed==1" class="subscription active">
                            Subscribed
                        </div>
                        <div *ngIf="customer.is_subscribed!==1" class="subscription inactive">
                            Not Subscribed
                        </div>
                    </div>
                    <div class="customer-actieve-status">
                        <div *ngIf="customer.is_valid==1" class="status active">
                            Active
                        </div>
                        <div *ngIf="customer.is_valid!==1" class="status inactive">
                            Inactive
                        </div>
                    </div>
                </div>
                <div class="section-footer"></div>
            </div> -->
                <!-- <div class="section tag-container">
                <div class="section-title">
                    TAGS
                </div>
                <div class="section-content">
                    <ng-container *ngIf="customerTag==undefined || customerTag.length==0">
                        <div class="no-tag-container">
                            <img src="assets/images/no-tag.png">
                            <span>No tags found !!!. Click <mat-icon>edit</mat-icon> to assign tags.</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="customerTag!==undefined && customerTag.length>0">
                        <ng-container *ngFor="let tag of customerTag">
                            <div class="customer-tag" [style.background]="tag.bg_color" [style.color]="tag.fg_color">
                                {{tag.name}}</div>
                        </ng-container>
                    </ng-container>
                </div>
                <div class="section-footer">
                    <button mat-icon-button class="edit-tag-btn" (click)="editTags()">
                        <mat-icon>edit</mat-icon>
                    </button>
                </div>
            </div> -->
                <div class="section purchase-container">
                    <div class="section-title">RFM INSIGHT(AS ON {{
                        customer.rfm_processed_on
                        | date:dateFormat
                        }})</div>
                    <div class="section-content purchase-container-content">
                        @if(customer.num_of_orders!=0){
                        <div class="purchase-amount purchase-content-item">
                            <span class="purchase-amount-title purchase-title">Recency Score</span>:
                            <span class="purchase-amount-value purchase-value"
                                *ngIf="customer.recency === 0">Today</span>
                            <span class="purchase-amount-value purchase-value"
                                *ngIf="customer.recency === 1">Yesterday</span>
                            <span class="purchase-amount-value purchase-value" *ngIf="customer.recency > 1">{{
                                customer.recency }} days back</span>
                        </div>
                        }

                        <div class="purchase-count purchase-content-item">
                            <span class="purchase-count-title purchase-title">Frequency Score</span>:
                            <span class="purchase-count-value purchase-value">{{
                                customer.frequency_score
                                }}</span>
                        </div>
                        <div class="purchase-latest purchase-content-item">
                            <span class="purchase-latest-title purchase-title">Monetary Score</span>:
                            <span class="purchase-latest-value purchase-value">{{
                                customer.monetary_score
                                }}</span>
                        </div>
                        @if(customer.num_of_orders!=0){
                        <div class="purchase-latest purchase-content-item">
                            <span class="purchase-latest-title purchase-title">Top purchase day</span>:
                            <span class="purchase-latest-value purchase-value"><a
                                    style="text-decoration: underline; cursor: pointer" (click)="openPaymentDialog()">{{
                                    customer.purchase_top_day }}</a></span>
                        </div>
                        <div class="purchase-latest purchase-content-item">
                            <span class="purchase-latest-title purchase-title">Top purchase time</span>:
                            <span class="purchase-latest-value purchase-value"><a
                                    style="text-decoration: underline; cursor: pointer"
                                    (click)="openPurchaseTimeDialog()">{{ customer.purchase_top_time
                                    }}</a></span>
                        </div>
                        }

                    </div>

                    <div class="section-footer"></div>
                </div>
                <div class="section purchase-container">
                    <div class="section-title">PURCHASE HISTORY</div>
                    <div class="section-content purchase-container-content">
                        <div class="purchase-count purchase-content-item">
                            <span class="purchase-count-title purchase-title">Total Orders </span>:
                            <span class="purchase-count-value purchase-value"> <a
                                    (click)="onRedirect(customer);resetState()">
                                    {{ customer.num_of_orders }}
                                </a></span>
                        </div>
                        <div class="purchase-latest purchase-content-item">
                            <span class="purchase-latest-title purchase-title">Total Spend</span>:
                            <span class="purchase-latest-value purchase-value">
               
                                {{(customer.total_order_value ?? 0 | currency: 'USD' : 'symbol' : '1.2-2') }}  
                </span>
                        </div>
                    </div>
                    <div class="section-footer"></div>
                </div>
                <div class="section purchase-container">
                    <div class="section-title">REWARD</div>
                    <div class="section-content purchase-container-content" style="margin-bottom: 20px">
                        <ng-container *ngIf="customer.customer_reward !== null; else zeroCounts">
                            <div class="status-indicators">
                                <div class="status-indicator active">
                                    {{ customer.customer_reward[0].active_items_count }}
                                    <div class="indicator-label">Active</div>
                                </div>
                                <div class="status-indicator used">
                                    {{ customer.customer_reward[0].used_items_count }}
                                    <div class="indicator-label">Used</div>
                                </div>
                                <div class="status-indicator expired">
                                    {{ customer.customer_reward[0].inactive_items_count }}
                                    <div class="indicator-label">Expired</div>
                                </div>
                            </div>
                        </ng-container>
                        <ng-template #zeroCounts>
                            <div class="status-indicators">
                                <div class="status-indicator active">
                                    0
                                    <div class="indicator-label">Active</div>
                                </div>
                                <div class="status-indicator used">
                                    0
                                    <div class="indicator-label">Used</div>
                                </div>
                                <div class="status-indicator expired">
                                    0
                                    <div class="indicator-label">Expired</div>
                                </div>
                            </div>
                        </ng-template>
                    </div>

                    <div class="section-footer"></div>
                </div>
                <div class="section-content" style="margin-left: 10px; margin-top: 10px">
                    <ng-container
                        *ngIf="customer.created_by !== null ||customer.created_by === 2 ||customer.created_by === 1 ">
                        <strong>Created</strong> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ customer.created_at | date: dateTimeFormat }} </ng-container><br /><br />
                    <ng-container
                        *ngIf=" customer.updated_by !== null ||customer.updated_by === 2 ||  customer.updated_by === 1  ">
                        <strong>Updated</strong> Admin &nbsp;&nbsp;&nbsp;&nbsp;
                        {{ customer.updated_at | date : dateTimeFormat }}
                    </ng-container>
                </div>
                <div class="section-footer"></div>
            </div>
            <div class="page-footer">
                @if(permission.can_edit){
                <button mat-button color="primary" class="btn-edit" (click)="onEditAction()">
                    <mat-icon>edit</mat-icon>Edit
                </button>
                }
                <!-- <button mat-button color="accent" class="btn-delete" (click)="onDeleteAction()">
                    <mat-icon>delete</mat-icon>Delete
                </button> -->
            </div>
        </div>


    </div>
    }@else if(show && customer == undefined){
    <div class="no-page-data">
        <img src="assets/images/side-bar-no-data.png" />
        <span class="no-data-info">Nothing to show. Please select a customer.</span>
    </div>
    }@else{}
</div>