import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';

@Component({
    selector: 'app-reward-segment-edit-dialog',
    templateUrl: './reward-segment-edit-dialog.component.html',
    styleUrls: ['./reward-segment-edit-dialog.component.scss'],
    standalone: false
})
export class RewardSegmentEditDialogComponent implements OnInit {
  selectedSegments: string = '';
  allSegments: any[] = [];
  
  displayedColumns: string[] = ['select', 'name'];
  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any>;

  constructor(
    public dialogRef: MatDialogRef<RewardSegmentEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.selectedSegments = data.selectedSegments;
    this.allSegments = data.segments;
    this.dataSource = new MatTableDataSource<any>(data.segments); // Initialize the data source
  }

  ngOnInit(): void {
    
    this.selection.select(...this.selectedSegments);
  }

 

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.allSegments.length;
    return numSelected === numRows;
  }

  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    

    this.selection.select(...this.allSegments);
  }

  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
    let selectedSegments = this.selection.selected;
    this.dialogRef.close(selectedSegments);
  }

  applyFilter(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }
}
