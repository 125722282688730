import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppConstants } from 'src/app/common/app.constants';
import { ToolbarService } from 'src/app/common/components/toolbar/toolbar.service';

@Component({
    selector: 'app-customer-optionbar',
    templateUrl: './customer-optionbar.component.html',
    styleUrls: ['./customer-optionbar.component.scss'],
    standalone: false
})
export class CustomerOptionbarComponent implements OnDestroy {

  @Input() show: boolean = true;

  @Input() tags: any[] = [];
  @Output() tagSelected: EventEmitter<any> = new EventEmitter();
  showTag: boolean = true;
  selectedTag: any;

  @Input() segments: any[] = [];
  @Output() segmentSelected: EventEmitter<any> = new EventEmitter();
  showSegment: boolean = true;
  selectedSegment: any;

  @Input() filters: any[] = [];
  @Output() filterSelected: EventEmitter<any> = new EventEmitter();
  showFilter: boolean = true;
  selectedFilter: any;
  

  @Output() reset: EventEmitter<any> = new EventEmitter();


  toolbarToggleEventSub: Subscription;


  constructor(private toolbarService: ToolbarService) {

    /**
     * Subscribes the toolbar events
     */
    this.toolbarToggleEventSub = this.toolbarService.menuToggleEvent.subscribe(() => {
      this.toggleOptionBar();
    });

  }

  /**
   * un register the subscription
   */
  ngOnDestroy(): void {
    this.toolbarToggleEventSub.unsubscribe();
  }

  /**
   * Toggles the display of optioin bar
   */
  toggleOptionBar(): void {
    this.show = !this.show;
  }

  onTagSelected(tag: any): void {
    this.resetQuickFilters();
    this.selectedTag = tag;
    this.tagSelected.emit(tag);
  }

  onSegmentSelected(segment: any): void {
    this.resetQuickFilters();
    this.selectedSegment = segment;
    this.segmentSelected.emit(segment);
  }

  /**
   * When filter is selected clear other selections
   * @param segment 
   */
  onFilterSelected(filter: any): void {
    this.resetQuickFilters();
    this.selectedFilter = filter;
    this.filterSelected.emit(filter);
  }

  /**
   * Reset all the selected quick filters
   */
  resetQuickFilters(reload?: boolean) {

    if (reload !== undefined && reload == true) {
      this.reset.emit({filter:(this.filterSelected!==undefined)});
    }
    this.selectedTag = undefined;
    this.selectedSegment = undefined;
    this.selectedFilter = undefined;
  }
}
