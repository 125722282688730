import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { CustomerTagEditDialogComponent } from '../../dialogs/customer-tag-edit-dialog/customer-tag-edit-dialog.component';
import { CustomerSegmentEditDialogComponent } from '../../dialogs/customer-segment-edit-dialog/customer-segment-edit-dialog.component';

@Component({
    selector: 'app-customer-select-action-list',
    templateUrl: './customer-select-action-list.component.html',
    styleUrls: ['./customer-select-action-list.component.scss'],
    standalone: false
})
export class CustomerSelectActionListComponent {

  @Input() tags: any;
  @Output() tagsApplied: EventEmitter<any> = new EventEmitter();
  
  @Input() segments: any;
  @Output() segmentApplied: EventEmitter<any> = new EventEmitter();
  
  constructor(
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) { }

  onTag(): void {
    this.editTags();
  }

  onSegment(): void {
    this.editSegment();
  }

  
  /**
   * Shows the tag edit dialog
   */
  editTags(): void {
    const dialogRef = this.dialog.open(CustomerTagEditDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      height: '600px',
      data: { custTags: [], tags: this.tags }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.tagsApplied.emit(result);
      }  
    });
  }

   /**
   * Shows the tag edit dialog
   */
   editSegment(): void {
    const dialogRef = this.dialog.open(CustomerSegmentEditDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      height: '600px',
      data: { custSegments: [], segments: this.segments }
    });
    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.segmentApplied.emit(result);
      }  
    });
  }

}
