<div class="edit-page-root-container reward-category-edit-root-container">
    <div class="page-content">
        <div class="page-header">
            <button mat-button class="nav-button" (click)="navBack()">
                <mat-icon>arrow_back</mat-icon>
            </button>
            <div class="title">
                {{title}}
            </div>
            <div class="action-btn-container">
                <button mat-flat-button class="action-btn btn-discard" (click)="navBack()">DISCARD</button>
                <button mat-flat-button class="action-btn btn-save" [hidden]="rewardCategoryInfo.is_system === 1" (click)="save()">SAVE</button>
            </div>
        </div>
            <!-- Add a notification element at the top -->
            @if(rewardCategoryInfo.is_system === 1){`
            <div id="notification" class="notification"
                style="text-align: center; margin-top:10px; background: yellow; color:red; padding:15px;">
                This is a system generated record.So cannot edit.
            </div>
            }
        <div class="page-details">
            <div class="detail-section">
                <form class="edit-form" [formGroup]="editForm" (ngSubmit)="save()">
                    <div class="content-container">
                        <mat-form-field class="code">
                            <mat-label>Code</mat-label>
                            <input matInput formControlName="code"  [attr.maxlength]="10" [readonly]="rewardCategoryInfo.id!==undefined && rewardCategoryInfo.id!==0">
                            <mat-error [@if]="editForm.controls['code'].hasError('required')">Code should be
                                entered.</mat-error>
                          
                        </mat-form-field>
                        <mat-form-field class="name">
                            <mat-label>Name</mat-label>
                            <input matInput formControlName="name" [attr.maxlength]="50" pattern="[a-zA-Z\s]*">
                            <mat-error *ngIf="editForm.controls['name'].hasError('required') && !editForm.controls['name'].value">Name should be entered</mat-error> 
                            <mat-error [@if]="editForm.controls['name'].hasError('pattern')">Only alphabetic characters allowed
                            </mat-error>
                        </mat-form-field>

                    
                        <mat-form-field class="description">
                            <mat-label>Description</mat-label>
                            <textarea matInput formControlName="description"  [attr.maxlength]="200"></textarea>
                        </mat-form-field>            
                        <div class="status-toggle-container">
                            <span class="status-toggle-title">Active</span>
                            <mat-slide-toggle formControlName="is_valid" class="status-toggle"></mat-slide-toggle>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>