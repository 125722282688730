
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { CustomerSegmentEditDialogComponent } from '../../customer/dialogs/customer-segment-edit-dialog/customer-segment-edit-dialog.component';
import { CustomerTagEditDialogComponent } from '../../customer/dialogs/customer-tag-edit-dialog/customer-tag-edit-dialog.component';
import { WinnerStatusConfirmationDialogComponent } from '../winner-status-confirmation-dialog/winner-status-confirmation-dialog.component';



@Component({
    selector: 'app-lucky-draw-winner-action-selection-list',
    templateUrl: './lucky-draw-winner-action-selection-list.component.html',
    styleUrl: './lucky-draw-winner-action-selection-list.component.scss',
    standalone: false
})
export class LuckyDrawWinnerActionSelectionListComponent {
  
  @Output() winnerStatusApplied: EventEmitter<any> = new EventEmitter();
  
  
  
  constructor(
    private dialog: MatDialog,
    private snackBarService: SnackBarService
  ) { }

 onIncludeClick() {
    // this.winnerStatusApplied.emit(1);
    const title = 'Include Members';
    this.openConfirmationDialog('Include', title);
  }
  
  onExcludeClick() {
    const title = 'Exclude Members'; 
    // this.winnerStatusApplied.emit(0);
    this.openConfirmationDialog('Exclude',title);
  }

  
       openConfirmationDialog(action: string, title: string) {
          const message = `Are you sure you want to ${action.toLowerCase()} the selected winners?`;
          const confirmDialogRef = this.dialog.open(WinnerStatusConfirmationDialogComponent, {
            data: { message,title }
          });
      
          confirmDialogRef.afterClosed().subscribe(confirmed => {
            if (confirmed) {
              this.winnerStatusApplied.emit(action === 'Include' ? 1 : 0);
            }
          });
        }
      
}
