import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { LuckyDrawSettingService } from '../../lucky-draw-settings.service';

@Component({
    selector: 'app-image-upload',
    templateUrl: './image-upload.component.html',
    styleUrls: ['./image-upload.component.scss'],
    standalone: false
})
export class LuckyDrawImageUploadComponent {
  selectedFile: File | null = null;
  previewUrl: any = null; // To store the image preview URL

  constructor(
    private builder: FormBuilder,
    private snackBar: MatSnackBar,
    public dialogRef: MatDialogRef<LuckyDrawImageUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public item: any,
    private luckyDrawService: LuckyDrawSettingService,// Inject your RewardService
    private snackBarService: SnackBarService
  ) { }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];

    // Display image preview
    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.previewUrl = reader.result;
      };
      reader.readAsDataURL(this.selectedFile);
    } else {
      this.previewUrl = null;
    }
  }

  uploadImage() {
    if (this.selectedFile) {
      // Call the correct service method to upload the image
      this.luckyDrawService.getImage(this.selectedFile, this.item.rewardId).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            const uploadedFileName = response.data.uploaded_file_name;
            const rewardId = this.item.rewardId;

            // Close the dialog and handle the uploaded file name
            this.dialogRef.close(uploadedFileName);

            // Rest of your code for success handling
            if (rewardId != undefined) {
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Image updated.',
                message: "Reward's image has been updated."
              }
              this.snackBarService.openSnackBar(snckBarData);

            }
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }
  cancel(): void {
    this.dialogRef.close();
  }
}


/**
 * Display a snackbar message with the specified message and type.
 * @param message The message to display in the snackbar
 * @param type The type of snackbar message ('success' or 'error')
 */



/**
 * Close the upload dialog
 */


