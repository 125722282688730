import { Component, EventEmitter, Input, Output, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { ColumnFilter } from '../../models/request-data.model';
import { formatDate } from '@angular/common';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatPaginator } from '@angular/material/paginator';
import { FilterService } from './filter.service';
import { AppConstants } from '../../app.constants';
import { SearchFilterSaveDialogComponent } from './dialogs/search-filter-save-dialog/search-filter-save-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarData } from '../snackbar/model/snackbar.model';
import { SearchFilterLoadDialogComponent } from './dialogs/search-filter-load-dialog/search-filter-load-dialog.component';
import { FilterDialogComponent } from './dialogs/filter-dialog/filter-dialog.component';
export declare type ValueType = 'date' | 'string' | 'number' | 'boolean' | 'list'  | 'phone';

export interface FilterListItemModel {
  title: string,
  column: string,
  type?: ValueType,
  operators?: FilterOperator[],
  values?: any[],
  excludeFreeSearch?: boolean,
  isSelected?: boolean,
  selectedValues?: any,

}

export interface PagingData {
  length?: number,
  pageSize: number,
  pageIndex: number,
}

export interface SearchParam {
  advSearch?: ColumnFilter[],
  freeSearch?: ColumnFilter[],
  pagingData?: PagingData
}

export interface FilterOperator {
  title: string,
  operator: string
}

export interface FilterValues {
  title: string,
  value: any,
}

export interface FilterDataModel {
  column: string,
  operator: string,
  value: any,
}

@Component({
    selector: 'app-search-filter',
    templateUrl: './search-filter.component.html',
    styleUrls: ['./search-filter.component.scss'],
    standalone: false
})
export class SearchFilterComponent implements OnInit {

  @ViewChild('mat-mdc-paginator-range-label') rangeLabel?: ElementRef;

  @Input() module: String = "";
  @Input() filter?: FilterListItemModel[] = [];
  @Input() pageSizeOptions: any = [5, 10, 20, 50, 100];

  @Input() hidePageSize: any;
  @Input() hideAdvancedFilter: boolean = false;
  @Input() pagingData: any;

  @Output() page: EventEmitter<any> = new EventEmitter();
  @Output() onApply: EventEmitter<any> = new EventEmitter();

  filterInfo: string = "0";
  showContent: boolean = false;
  selectedFilters: FilterListItemModel[][] = [];
  selectedColumnFilter: ColumnFilter[] = [];
  freeSearchColumns: FilterListItemModel[] | undefined;
  freeSearchValue: any;

  @ViewChild(MatPaginator) paginator?: MatPaginator;

  showPagingMenu: boolean = false;
  isFavorate: boolean = false;
  snackBarService: any;
  filterName: any;
  user: any;
  // loadedFilter: any;
  searchInfo: any; //to save and load from db

  /**
   *  Sample format is givne below
   *  Do note remove this.
   [
    {
      title: "First Name",
      column: "f_name",
      operators: [
        { title: "is", operator: "=" },
        { title: "is not", operator: "!=" }
      ]
    },
    {
      title: "Last Name",
      column: "l_name",
      operators: [
        { title: "is", operator: "=" },
        { title: "is not", operator: "!=" }
      ]
    },
    {
      title: "Age",
      column: "age",
      operators: [
        { title: "is", operator: "=" },
        { title: "is not", operator: "!=" },
        { title: "greater than ", operator: ">" },
        { title: "less than ", operator: "<" },
      ]
    },
    {
      title: "Date Of Birth",
      column: "dob",
      type: 'date',
      operators: [
        { title: "is", operator: "=" },
        { title: "is not", operator: "!=" },
        { title: "greater than ", operator: ">" },
        { title: "less than ", operator: "<" },
      ]
    },
    {
      title: "Gender",
      column: "gender",
      operators: [
        { title: "is", operator: "=" },
        { title: "is not", operator: "!=" }
      ],
      values: [
        { title: "Male", value: "m" },
        { title: "Female", value: "f" },
      ]
    },
    {
      title: "Active",
      column: "is_active",
      operators: [
        { title: "is", operator: "=" },
        { title: "is not", operator: "!=" }
      ],
      values: [
        { title: "Active", value: "1" },
        { title: "Not Active", value: "0" },
      ]
    },
  ];
   *
   */

  constructor(private renderer: Renderer2, private elementRef: ElementRef, private filterService: FilterService, private dialog: MatDialog) {
    (window as any).showPagingMenu = this.showPagingMenu;
    let sesUser = sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
    this.user = (sesUser !== null) ? JSON.parse(sesUser) : { id: 0 };

    this.searchInfo = { id: 0, name: "", isFavorite: 0, module: this.module, filter: undefined, user_id: this.user.id };

  }

  // ngOnChanges(changes: SimpleChanges): void {
  //   if (changes['pagingData']) {

  //     this.pageIndex=this.pagingData.pageIndex;
  //     this.length=this.pagingData.length;
  //     this.pageSize=this.pagingData.pageSize;
  //   }
  // }
  /**
   * Initiaizes various varaibles
   */
  ngOnInit(): void {
    this.freeSearchColumns = this.filter?.filter((f: FilterListItemModel) => {
      return ((f.type == undefined || f.type == 'string' || f.type == "number" || f.type == 'date'|| f.type == 'phone') && (f.excludeFreeSearch == undefined || f.excludeFreeSearch == false));
    });
    const rangeLabelElement = this.elementRef.nativeElement.querySelector('.mat-mdc-paginator-range-label');
    rangeLabelElement.classList.add('cust_page_display_style');
    this.renderer.listen(rangeLabelElement, 'click', () => {
      this.togglePageMenu();
    });
    // this.onFilterAdd();
  }


  setColumnFilter(columnFilter: any): void {
    this.selectedColumnFilter = columnFilter;
    this.onFilterApply();
  }


  /**
   * Toggles the display of filter contents
   */
  onToggleFilter(): void {

    const dialogRef = this.dialog.open(FilterDialogComponent, {
      width: '99vw',
      maxWidth: '850px',
      height: '80vh',
      data: { module: this.module, filterOptions: this.filter, columnFilter: this.selectedColumnFilter }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.pagingData.pageIndex = 0;
        this.setColumnFilter(result);
      } else {
        // console.log('filter canceled');
      }
    });

  }

  /**
   * Toggle the display of pagination bar
   */
  togglePageMenu(): void {
    this.showPagingMenu = !this.showPagingMenu;

  }

  /**
   * hides the pagination bar
   */
  onClosePageMenu(): void {
    this.showPagingMenu = false;
  }

  /**
   * Move to first page
   */
  onFirstPage(): void {
    this.paginator?.firstPage();
  }

  /**
   * Move to last page
   */
  onLastPage(): void {
    this.paginator?.lastPage();
  }

  onPageSizeChanged(): void {
    let event: any = { pageIndex: this.pagingData.pageIndex, pageSize: this.pagingData.pageSize };
    this.onePageEvent(event);
  }

  /**
   * Add new filter 
   */
  onFilterAdd(): void {

    let newFilter = JSON.parse(JSON.stringify(this.filter));
    this.selectedFilters.push(newFilter);
    this.selectedColumnFilter.push({ column: "", operator: "", value: "" ,type:""})
  }

  /**
   * 
   * @param filter Remove the filter from selcted filters
   */
  onFilterRemove(index: any): void {
    this.selectedFilters.splice(index, 1);
    this.selectedColumnFilter.splice(index, 1);

  }

  /**
   * Remove all filters from selected filter array.
   */
  onFilterReset(): void {
    this.selectedFilters.length = 0;
    this.selectedColumnFilter.length = 0;
    this.searchInfo = { id: 0, name: "", isFavorite: 0, module: this.module, filter: undefined, user_id: this.user.id };

  }

  /**
   * Reset ther pagination when new filter is applied
   */
  onFilter() {
    this.pagingData.pageIndex = 0;
    this.onFilterApply();
  }
  /**
   * Emit the event with the selected filters
   */
  onFilterApply(): void {
    let searchFilterData: SearchParam = { advSearch: this.selectedColumnFilter, freeSearch: this.getFreeSearchParams() };
    this.filterInfo = this.selectedColumnFilter.length + "";
    this.showContent = false;
    this.onApply.emit(searchFilterData);
  }

  /**
   * Loads predefined filter from saved list
   */
  onFilterLoad(): void {

    let searchInfo = { search: { filters: { simpleSearch: [], advSearch: [{ column: "module", operator: "=", value: this.module }, { column: "user_id", operator: "=", value: this.user.id }], scope: { limit: 50, offset: 0 } }, sort: [] } }
    this.filterService.getFilter(searchInfo).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          let searchFilter = response.data.filters;
          const dialogRef = this.dialog.open(SearchFilterLoadDialogComponent, {
            width: '90%',
            maxWidth: '500px',
            height: '500px',
            data: searchFilter
          });
          dialogRef.afterClosed().subscribe(result => {
            if (result) {
              this.searchInfo = result;
              this.searchInfo.filter = JSON.parse(result.filter);
              this.setFilter(this.searchInfo.filter);

            } else {
              console.log('filter canceled');
            }
          });


        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        // handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });


  }

  saveClicked(): void {

    this.searchInfo.module = this.module;
    this.searchInfo.filter = this.selectedColumnFilter;
    const dialogRef = this.dialog.open(SearchFilterSaveDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      height: '295px',
      data: this.searchInfo
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.searchInfo = result;
      } else {
        console.log('filter canceled');
      }
    });
  }

  /**
   * Save the filters
   */
  onFilterSave(): void {
    let sesUser = sessionStorage.getItem(AppConstants.SESSION_VAR_USER);
    let userId = (sesUser !== null) ? JSON.parse(sesUser).id : 0;
    let data = { name: 'myfilter', isFavorite: true, module: this.module, filter: this.selectedColumnFilter, user_id: userId };
    const dialogRef = this.dialog.open(SearchFilterSaveDialogComponent, {
      width: '90%',
      maxWidth: '500px',
      height: '600px',
      data: data
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

      } else {
        console.log('filter canceled');
      }
    });


  }

  /**
   * Set the filters from pre loaded json
   */
  setFilter(columFilters: ColumnFilter[], apply?: boolean): void {
    this.selectedFilters.length = 0;
    this.selectedColumnFilter.length = 0;
    columFilters.forEach(f => {
      if (this.filter?.some((ft) => ft.column == f.column)) {
        let newFilter = JSON.parse(JSON.stringify(this.filter));
        this.selectedFilters.push(newFilter);
        this.selectedColumnFilter.push({ column: f.column, operator: f.operator, value: f.value,type: f.type })
      }
    });
    if (apply) {
      this.onFilterApply();
    }
  }

  /**
   * Sets the scop, simple and advanced filters
   * @param searchFilter 
   */
  setSearchFilter(searchFilter: any, apply?: boolean): void {

    var simpleSearchFilter = searchFilter.simpleSearch;
    var advSearchFilter = searchFilter.advSearch;

    if (simpleSearchFilter !== undefined && simpleSearchFilter.length > 0) {
      this.freeSearchValue = simpleSearchFilter[0].value;
    }

    if (advSearchFilter !== undefined && advSearchFilter.length > 0) {
      // this.searchInfo.filter =advSearchFilter;
      this.setFilter(advSearchFilter);
    }
    if (apply)
      this.onFilterApply();
  }

  /**
   * Page event
   * @param event 
   */
  onePageEvent(event: any): void {
    this.page.emit(event);
  }

  /**
   * Builds the free search array
   * @returns 
   */
  onFreeSearch() {
    this.onFilterApply();
  }

  /**
 * Builds the free search array
 * @returns 
 */
  getFreeSearchParams(): ColumnFilter[] {
    let freeSearchFilter: ColumnFilter[] | undefined;
    if (this.freeSearchValue !== undefined && this.freeSearchValue.length > 1) {
      freeSearchFilter = this.freeSearchColumns?.map((col: FilterListItemModel) => {
        //  let value=(col.type=='date'?formatDate(this.freeSearchValue,'yyyy/mm/dd',''):this.freeSearchValue);
        return { column: col.column, operator: "like", value: this.freeSearchValue ,type:col.type};
      })
    }
    return (freeSearchFilter !== undefined) ? freeSearchFilter : [];
  }

  loadFilterOnDemand(param: any): void {
    let columnFilter = JSON.parse(param.filter);
    this.setFilter(columnFilter, true);

  }
}
