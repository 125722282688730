import { Component, Input } from '@angular/core';
import { StateManagerService } from '../../services/state.manager.service';
@Component({
    selector: 'app-extra-pages-menu',
    templateUrl: './extra-pages-menu.component.html',
    styleUrls: ['./extra-pages-menu.component.scss'],
    standalone: false
})
export class ExtraPagesMenuComponent {
  @Input() show: boolean = false
  constructor(private stateManagerService:StateManagerService) {}

  resetState() {
    // Call the resetState function
    this.stateManagerService.resetState();
  }
}
