import { Component, Input, OnChanges, SimpleChanges, OnInit, Output, EventEmitter } from '@angular/core';
import { CustomerModel } from '../../model/customer.model';
import { AppConstants } from 'src/app/common/app.constants';
import { CustomerTagEditDialogComponent } from '../../dialogs/customer-tag-edit-dialog/customer-tag-edit-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CustomerService } from '../../customer.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { PaymentTrendDialogComponent } from '../../dialogs/payment-trend-dialog/payment-trend-dialog.component';
import { PurchaseTimeDialogComponent } from '../../dialogs/purchase-time-dialog/purchase-time-dialog.component';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';
import { Router } from '@angular/router';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { PermissionService } from 'src/app/auth/permission.service';

@Component({
    selector: 'app-customer-summary',
    templateUrl: './customer-summary.component.html',
    styleUrls: ['./customer-summary.component.scss'],
    standalone: false
})
export class CustomerSummaryComponent implements OnChanges, OnInit  {


  @Input() customer: any;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();
  show: boolean = false;
  editNote:boolean = false;
  oldNoteValue:string='';
  dateFormat: string;
  dateTimeFormat: string;
  permission: any;

  constructor(
    private dialog: MatDialog,
    private CustomerService: CustomerService,
    private snackBarService: SnackBarService,
    private systemSettingService: SystemSettingsService,
    private router: Router,
    private stateManagerService: StateManagerService,
    private permissionService:PermissionService


  ) {
    this.dateTimeFormat = systemSettingService.getDefaultDateTimeFormat();
    this.dateFormat = systemSettingService.getDefaultDateFormat();
    this.permission = permissionService.getPagePermission('customer');
  }
  
  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['customer']) {
      this.show = (this.customer !== undefined);
      //this.customerTag=this.getTags();
    }
    
  }

  close(): void {
    this.show = !this.show;
  }
   /**
   * enable  customer notes edit
   */
  enableEditMode(): void {
    this.editNote=true;
    this.oldNoteValue=this.customer.notes;
   
  }
   /**
   * reset customer notes
   */
  resetNote():void {
    this.editNote=false;
    this.customer.notes= this.oldNoteValue;
  }
  onRedirect(customerInfo: CustomerModel): void {
    // Replace 'customer_id_value' with the actual customer ID you want to pass
    const customerId = customerInfo.id;
    console.log('Redirecting to orders for customer ID:', customerInfo.id);


    // Navigate to the orders page with the customer ID as a query parameter
    this.router.navigate(['order',customerId]);

  }
  resetState() {
    // Call the resetState function
    this.stateManagerService.resetState();
  }
  /**
   * Save customer notes
   */
  saveNote(): void{
  let  customerNote={'customer_id':this.customer.id,'note':this.customer.notes};
    this.CustomerService.updateCustomerNote(customerNote).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
         this.editNote=false;
          let snckBarData: SnackBarData = {
            type: 'success',
            title: 'Saved',
            message: 'Notes for ' + this.customer.first_name + " has been updated succesfully."
          }
          this.snackBarService.openSnackBar(snckBarData);
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        // handle error
        let snackBarData: SnackBarData = {
          message: 'Failed to update. Connection to the server failed',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }
  getMonthName(monthNumber: number): string {
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    return monthNames[monthNumber - 1] || '';
  }


  /**
   * 
   * @returns Fiters the cuetomer tags
   */
  // getTags(): any[] {
  //   // let sessionTag =sessionStorage.getItem(AppConstants.SESSION_VAR_TAGS);
  //   // this.tags = (sessionTag!==null)?JSON.parse(sessionTag):[];
  //   let customerTags:any[]=[];
  //   if(this.customer.tags!==null && this.customer.tags.length>0){
  //     let custTagIds=this.customer.tags.split(',');

  //     for(const id of custTagIds){
  //       let tag=this.tags.find( (t: any) => t.id===Number(id));
  //       customerTags.push(tag);
  //     }
  //   }

  //   return customerTags;
  // }

  /**
   * Shows the tag edit dialog
   */
  // editTags(): void{
  //   const dialogRef = this.dialog.open(CustomerTagEditDialogComponent, {
  //     width: '90%',
  //     maxWidth: '500px',
  //     height: '600px',
  //     data: {custTags:this.customer.tags,tags:this.tags}
  //   });
  //   dialogRef.afterClosed().subscribe((result: any) => {
  //     if (result) {

  //       const tagIdArr = result.map((tag: any) => tag.id);
  //       let tags=tagIdArr.join(',');
  //       let requestData={customers:[this.customer.id],tags:tags};
  //       this.CustomerService.updateCustomerTag(requestData).subscribe({
  //         next: (response) => {
  //           if (response.status == 'SUCCESS') {
  //             // handle success
  //            this.customer.tags=tags;
  //            this.customerTag=this.getTags();
  //             let snckBarData: SnackBarData = {
  //               type: 'success',
  //               title: 'Saved',
  //               message: 'The record for ' + this.customer.first_name + " has been updated succesfully."
  //             }
  //             this.snackBarService.openSnackBar(snckBarData);
  //           } else {
  //             let snackBarData: SnackBarData = {
  //               message: response.message,
  //               title: 'Failed!!!',
  //               type: 'error'
  //             }
  //             this.snackBarService.openSnackBar(snackBarData);
  //           }
  //         },
  //         error: (error) => {
  //           // handle error
  //           let snackBarData: SnackBarData = {
  //             message: 'Failed to update. Connection to the server failed',
  //             title: 'Failed!!!',
  //             type: 'error'
  //           }
  //           this.snackBarService.openSnackBar(snackBarData);
  //         }
  //       });
  //     } else {
  //       console.log('Edit canceled');
  //     }
  //   });
  // }
  openPaymentDialog(): void {
    const dialogRef = this.dialog.open(PaymentTrendDialogComponent, {
      width: '400px',
      maxHeight: '90%',
      data: { customer: this.customer }
    });// Subscribe to the afterClosed event
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(`Dialog result: ${result}`);
        // Perform any actions based on the result if needed
      } else {
        console.log('Dialog canceled');
        // Perform any actions for cancelation if needed
      }
    });
  
   
  }

  //
  openPurchaseTimeDialog(): void {
    const dialogRef = this.dialog.open(PurchaseTimeDialogComponent, {
      width: '400px',
      maxHeight: '90%',
      data: { customer: this.customer }
    });// Subscribe to the afterClosed event
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log(`Dialog result: ${result}`);
        // Perform any actions based on the result if needed
      } else {
        console.log('Dialog canceled');
        // Perform any actions for cancelation if needed
      }
    });
  
   
  }
    /**
 * On edit the item
 * Show the edit dialog
 */
    onEditAction() {
      this.onEdit.emit(this.customer);
    }
  
    onDeleteAction() {
      this.onDelete.emit(this.customer);
    }
  
}
