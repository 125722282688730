
import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AppConstants } from 'src/app/common/app.constants';
// import { SelectionModel } from 'src/app/common/models/selection.model';
@Component({
    selector: 'app-customer-tag-edit-dialog',
    templateUrl: './customer-tag-edit-dialog.component.html',
    styleUrls: ['./customer-tag-edit-dialog.component.scss'],
    standalone: false
})


export class CustomerTagEditDialogComponent implements OnInit {

  custTags: string = "";
  customerTags: any[] = [];
  allTags: any[] = [];
  // tagSelectionModel: SelectionModel[] = [];

  displayedColumns: string[] = ['select', 'name'];
  selection = new SelectionModel<any>(true, []);
  dataSource: MatTableDataSource<any>;

  constructor(
    public dialogRef: MatDialogRef<CustomerTagEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

    this.custTags = data.custTags;
    this.allTags =  data.tags;
    this.customerTags = this.getCustomerTags();
    this.dataSource = new MatTableDataSource(this.allTags);
  }

  ngOnInit(): void {
    this.selection.select(...this.customerTags);
  }

  /**
   * 
   * @returns Extract tags from all tags using ids
   */
  getCustomerTags(): any[] {
    let customerTags: any[] = [];
    if (this.custTags !== null && this.custTags.length > 0) {
      let custTagIds = this.custTags.split(',');

      for (const id of custTagIds) {
        let tag = this.allTags.find((t: any) => t.id === Number(id));
        customerTags.push(tag);
      }
    }

    return customerTags;
  }

  /**
   * Returns the selectioin model tag array with the customer tags selected
   */
  // getTagArray(): SelectionModel[] {

  //   let tagSelectionArray: SelectionModel[] = [];
  //   tagSelectionArray = this.allTags.map((t) => {
  //     let tagSelection: SelectionModel = { selected: (this.custTags.indexOf(t.id.toString()) >= 0), item: t };
  //     return tagSelection;
  //   });

  //   return tagSelectionArray;
  // }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.allTags.length;
    return numSelected === numRows;
  }


  /** 
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.allTags);

  }

  /** The label for the checkbox on the passed row */
  // checkboxLabel(row?: any): string {
  //   if (!row) {
  //     return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
  //   }
  //   return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  // }

  /**
   * retrun count of selected tags
   */
  selectedCount(): number {
    return this.selection.selected.length;
     
    }
 

  /**
  * Close the edit dialog
  */
  cancel(): void {
    this.dialogRef.close();
  }

  save(): void {
   let selectedTags=this.selection.selected;
   //console.log(selectedTags);
   this.dialogRef.close(selectedTags);
   }

  /**
   * Apply the filter on table contents4
   * 
   * @param filterValue 
   */
  applyFilter(event: any) {
    let filterValue = event.target.value;
    filterValue = filterValue.trim();
    filterValue = filterValue.toLowerCase();
    this.dataSource.filter = filterValue;
  }

}
