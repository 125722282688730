import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RewardComponent } from '../../reward.component';
import { Color, ScaleType } from '@swimlane/ngx-charts';
import { AppConstants } from 'src/app/common/app.constants';
import { SystemSettingsService } from 'src/app/common/services/system-settings.service';
import { PermissionService } from 'src/app/auth/permission.service';


export class YourModule { }


@Component({
    selector: 'app-reward-summary',
    templateUrl: './reward-summary.component.html',
    styleUrls: ['./reward-summary.component.scss'],
    standalone: false
})
export class RewardSummaryComponent implements OnChanges {
  @Input() reward: any;
  @Output() onEdit: EventEmitter<any> = new EventEmitter();
  @Output() onDelete: EventEmitter<any> = new EventEmitter();

  show: boolean = false;
  rewards: any;
  barChartData: any[] = [];
/**
 * ng Charts color setting
 */
  colorScheme: Color = {
    domain: ['#5AA454', '#A10A28', '#C7B42C', '#AAAAAA'],
    name: 'cool',
    selectable: true,
    group: ScaleType.Ordinal,
  };

/**
 * ng Charts element declaration
 */
  gradient: boolean = true;
  showXAxis: boolean = true;
  showYAxis: boolean = true;
  showLegend: boolean = true;
  showXAxisLabel: boolean = true;
  showYAxisLabel: boolean = true;
  xAxisLabel: string = 'X-Axis Label';
  yAxisLabel: string = 'Y-Axis Label';
  showLabels: boolean = true;
  isDoughnut: boolean = false;
  barPaddingValue = 5;
  dateFormat: string;
  dateTimeFormat: string;
  permission: any;



  constructor(
    private dialog: MatDialog,
    private rewardComponent: RewardComponent,
    private systemSettingService: SystemSettingsService,
    private permissionService:PermissionService,
  ) {
    let sessionTag = sessionStorage.getItem(AppConstants.SESSION_VAR_TAGS);
    this.rewards = (sessionTag !== null) ? JSON.parse(sessionTag) : [];
    this.dateFormat = systemSettingService.getDefaultDateFormat();
    this.dateTimeFormat = systemSettingService.getDefaultDateTimeFormat();
    this.permission = permissionService.getPagePermission('reward');
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reward'] && this.reward) {
      //this.show = true;
      this.generateChartData();
    }
  }

  close(): void {
    this.show = !this.show;
  }
/**
 * ng Charts getting count from api
 */
  private generateChartData(): void {
    const successRate = this.reward.success_rate;
    
    if (successRate) {
      const recipientsCount = successRate.recipients_count || 0;
      const claimantsCount = successRate.claimants_count || 0;

      this.barChartData = [
        { name: `Recipients (${recipientsCount})`, value: recipientsCount },
        { name: `Claimants (${claimantsCount})`, value: claimantsCount },
      ];
      
    }
  }
/**
 * update particular record
 */
  onEditAction() {
    this.onEdit.emit(this.reward);
  }

/**
 * delete particular record
 */
  onDeleteAction() {
    this.onDelete.emit(this.reward);
  }
/**
 * Publish date calculation
 */
  getUpdatedDate(reward: any): string {
    if (reward.status === 2) {
      const publishedDate = new Date(reward.published_on);
      const validForDays = reward.valid_for || 0;
      const updatedDate = new Date(publishedDate.getTime() + validForDays * 24 * 60 * 60 * 1000);
      return updatedDate.toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    } else {
      return new Date(reward.published_on).toLocaleDateString('en-US', { year: 'numeric', month: 'short', day: 'numeric' });
    }
  }
}
