import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'app-purchase-time-dialog',
    templateUrl: './purchase-time-dialog.component.html',
    styleUrl: './purchase-time-dialog.component.scss',
    standalone: false
})
export class PurchaseTimeDialogComponent implements OnInit {

  customer: any;

  constructor(
    public dialogRef: MatDialogRef<PurchaseTimeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {

   
  }

  ngOnInit(): void {
    this.customer = this.data.customer; // Assign data to the customer property

  }
  
   
  /**
  * Close the edit dialog
  */
  cancel(): void {
    this.dialogRef.close();
  }

 
}
