<div class="filter-item-container">
    <div class="title-selector">
        <!-- <mat-label>Filter on</mat-label> -->
        <mat-select [(value)]="selectedFilterOn" (selectionChange)="filterChanged($event)">
            <mat-option *ngFor="let item of filter" [value]="item">{{item.title}}</mat-option>
        </mat-select>
    </div>

    <div *ngIf="selectedFilterOn?.operators.length>0" class="operator-selector">
        <!-- <mat-label>Operator</mat-label> -->
        <mat-select [(value)]="search.operator">
            <mat-option *ngFor="let operator of selectedFilterOn?.operators"
                [value]="operator.operator">{{operator.title}}</mat-option>
        </mat-select>
    </div>
    <ng-container *ngIf="selectedFilterOn?.values?.length>0; else userinput">
        <div class="value-selector">
            <!-- <mat-label>Value</mat-label> -->
            <ng-container *ngIf="selectedFilterOn?.type=='list'">
                <mat-select [(value)]="search.value" multiple>
                    <mat-option *ngFor="let value of selectedFilterOn?.values"
                        [value]="value.value">{{value.title}}</mat-option>
                </mat-select>
            </ng-container>
            <ng-container *ngIf="selectedFilterOn?.type!=='list'">
                <mat-select [(value)]="search.value">
                    <mat-option *ngFor="let value of selectedFilterOn?.values"
                        [value]="value.value">{{value.title}}</mat-option>
                </mat-select>
            </ng-container>
        </div>
    </ng-container>
    <ng-template #userinput>
        <div *ngIf="selectedFilterOn?.type==undefined || selectedFilterOn?.type=='string'" class="value-selector">
            <!-- <mat-label>Value</mat-label> -->
            <input type="text" [(ngModel)]="search.value">
            <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="search.value=''">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngIf="selectedFilterOn?.type=='phone'" class="value-selector">
            <!-- <mat-label>Value</mat-label> -->
            <input type="text" [(ngModel)]="search.value">
            <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="search.value=''">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngIf="selectedFilterOn?.type=='number'" class="value-selector">
            <!-- <mat-label>Value</mat-label> -->
            <input matInput type="number" [(ngModel)]="search.value">
            <button *ngIf="search.value" matSuffix mat-icon-button aria-label="Clear" (click)="search.value=''">
                <mat-icon>close</mat-icon>
            </button>
        </div>
        <div *ngIf="selectedFilterOn?.type=='date'" class="value-selector">
            <!-- <mat-label>MM/DD/YYYY</mat-label> -->
            <input matInput [matDatepicker]="datepicker" [(ngModel)]="search.value">
            <!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
            <mat-datepicker-toggle matIconSuffix [for]="datepicker"></mat-datepicker-toggle>
            <mat-datepicker #datepicker>
                <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply>Apply</button>
                </mat-datepicker-actions>
            </mat-datepicker>
        </div>
    </ng-template>
    <button mat-icon-button class="delete-button" (click)="onDeleteClick()">
        <mat-icon mat-button>delete</mat-icon>
    </button>

</div>