import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TagModel } from '../model/tag.model';
import { TagService } from '../tag.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';

@Component({
    selector: 'app-tag-edit',
    templateUrl: './tag-edit.component.html',
    styleUrls: ['./tag-edit.component.scss'],
    standalone: false
})


export class TagEditComponent implements DirtyCheck{

  title: string = "New Tag";
  editForm: FormGroup;
  tagInfo: TagModel = { bg_color:"#ffffff",fg_color:"#000000", is_valid: 1 };
  isFormDirty: boolean = false;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private tagService: TagService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let tagId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.tagInfo.code, [Validators.required, Validators.maxLength(50)]),
      name: this.builder.control(this.tagInfo.name, [Validators.required]),
      description: this.builder.control(this.tagInfo.description),
      fg_color: this.builder.control(this.tagInfo.fg_color),
      bg_color: this.builder.control(this.tagInfo.bg_color),
      is_valid: this.builder.control(this.tagInfo.is_valid, [])
    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (tagId !== null && parseInt(tagId) !== 0) {
      this.setTagInfo(tagId);
    }
  }

  ngOnInit(): void { }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/tag']);
    
  }

  /**
   * If tas is is null set an empty tag info
   * if tag id is not null call api to rtrive the info
   * @param tagId 
   */
  setTagInfo(tagId: any): void {

    let requestData = { search: { filters: { simpleSearch: [], advSearch: [{ column: "id", operator: "=", value: tagId }], scope: { limit: 10, offset: 0 } }, sort: [] } };
    let response = this.tagService.getTagList(requestData).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.tagInfo = response.data.tags[0];
          this.editForm.patchValue(this.tagInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.tagInfo.id == 0) ? "New Tag" : "Editing : " + this.tagInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      Object.assign(this.tagInfo, this.editForm.value);
      this.tagService.update(this.tagInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.tagInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }
}
