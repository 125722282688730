import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SegmentModel } from '../model/segment.model';
import { SegmentService } from '../segment.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';

@Component({
    selector: 'app-segment-edit',
    templateUrl: './segment-edit.component.html',
    styleUrls: ['./segment-edit.component.scss'],
    standalone: false
})


export class SegmentEditComponent implements DirtyCheck{
  
  title: string = "New Segment";
  editForm: FormGroup;
  segmentInfo: SegmentModel = {
    is_valid: 1,
  };
  isFormDirty: boolean = false;
  ratingList : SegmentModel[]= [];
  
  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private segmentService: SegmentService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let segmentId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.segmentInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.segmentInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.segmentInfo.description),
      recency_rating_id: this.builder.control(this.segmentInfo.recency_rating_id, [Validators.required]),
      frequency_rating_id: this.builder.control(this.segmentInfo.frequency_rating_id, [Validators.required]),
      monetary_rating_id: this.builder.control(this.segmentInfo.monetary_rating_id, [Validators.required]),
      loyalty_per_dollar:this.builder.control(this.segmentInfo.loyalty_per_dollar, [Validators.required]),
      is_valid: this.builder.control(this.segmentInfo.is_valid, [])
    });
    
    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (segmentId !== null && parseInt(segmentId) !== 0) {
      this.setSegmentInfo(segmentId);
    }
  }

  
  ngOnInit(): void {
    this.loadRating();    
   }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty():boolean{
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {
   
      this.router.navigate(['/segment']);
    
  }
/**
 *  Load rfm rating list in select 
 */
  loadRating() {

    let response = this.segmentService.getRfmRatingList().subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.ratingList = response.data.rating_list;

        
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
       
    });

  }
  /**
   * If tas is is null set an empty segment info
   * if segment id is not null call api to rtrive the info
   * @param segmentId 
   */
  setSegmentInfo(segmentId: any): void {

    let response = this.segmentService.getSegmentById(segmentId).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.segmentInfo = response.data.segment;
          this.editForm.patchValue(this.segmentInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.segmentInfo.id == 0) ? "New Segment" : "Editing : " + this.segmentInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {

    if (this.editForm.valid) {
      // this.segmentInfo = this.editForm.value;
      Object.assign(this.segmentInfo, this.editForm.value);
      this.segmentService.update(this.segmentInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.segmentInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }else{
      this.editForm.markAllAsTouched();
    }
  }
}
