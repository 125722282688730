import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserGroupModel, UserGroupPrmissionModel } from '../model/user-group.model';
import { UserGroupService } from '../user-group.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DirtyCheck } from 'src/app/common/guards/form-deactivate.guard';
import { HttpClient } from '@angular/common/http';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { trigger } from '@angular/animations';

@Component({
    selector: 'app-user-group-edit',
    templateUrl: './user-group-edit.component.html',
    styleUrls: ['./user-group-edit.component.scss'],
    animations: [
        trigger('if', [
        // ... animation states and styles
        ])
    ],
    standalone: false
})
export class UserGroupEditComponent implements DirtyCheck {



  title: string = "New User Group";
  editForm: FormGroup;
  userGroupInfo: UserGroupModel = { is_valid: 1 };
  isFormDirty: boolean = false;

  constructor(private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    private userGroupService: UserGroupService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,) {

    let userGroupId = this.route.snapshot.paramMap.get('id');
    this.editForm = this.builder.group({
      code: this.builder.control(this.userGroupInfo.code, [Validators.required, Validators.maxLength(10)]),
      name: this.builder.control(this.userGroupInfo.name, [Validators.required, Validators.maxLength(50)]),
      description: this.builder.control(this.userGroupInfo.description),
      is_valid: this.builder.control(this.userGroupInfo.is_valid, [])
    });

    this.editForm.valueChanges.subscribe(() => {
      this.isFormDirty = true;
    });

    if (userGroupId !== null && parseInt(userGroupId) !== 0) {
      this.setUserGroupInfo(userGroupId);
    }
  }

  ngOnInit(): void { }

  /**
   * Dirtyness check
   * @returns 
   */
  isDirty(): boolean {
    return this.isFormDirty;
  }

  /**
   * checks the form is edited
   */
  navBack(): void {

    this.router.navigate(['/user-group']);

  }

  /**
   * If tas is is null set an empty reward category info
   * if reward category id is not null call api to rtrive the info
   * @param userGroupId 
   */
  setUserGroupInfo(userGroupId: any): void {

    let requestData = { search: { filters: { simpleSearch: [], advSearch: [{ column: "id", operator: "=", value: userGroupId }], scope: { limit: 10, offset: 0 } }, sort: [] } };
    let response = this.userGroupService.getUserGroupList(requestData).subscribe({
      next: (response) => {
        if (response.status == 'SUCCESS') {
          this.userGroupInfo = response.data.user_groups[0];
          this.editForm.patchValue(this.userGroupInfo);
          this.title = this.getTitle();
          this.isFormDirty = false;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: 'Failed to get data from server. Connection to the server failed.',
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.userGroupInfo.id == 0) ? "New User Group" : "Editing : " + this.userGroupInfo.name;
    return title;
  }

  /**
     * On save
     * if the details are valid call the api to update the changes.
     */
  save(): void {
    if (!this.editForm.valid){ let snackBarData: SnackBarData = {
      message: "Some of fields are empty or conatains invalid data.",
      title: "Failed!!!",
      type: "error",
    }; 
    this.snackBarService.openSnackBar(snackBarData);
    return;}
    if (this.editForm.valid) {
      // this.userGroupInfo = this.editForm.value;
      Object.assign(this.userGroupInfo, this.editForm.value);
      this.userGroupService.update(this.userGroupInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.isFormDirty = false;
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.userGroupInfo.name + " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }
}

