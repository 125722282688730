import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {
  title = "St Pierre's CRM";

  constructor(private authService: AuthService) {

  }

  isLoggedIn() {
    return this.authService.isloggedin();
  }
}
