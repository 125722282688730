import { Component, ViewChild } from '@angular/core';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { CustomerModel } from './model/customer.model';
import { FloatingMenuOption } from 'src/app/common/components/master-floating-menu/model/master-floating-model';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { ColumnFilter, RequestData, SearchSort } from 'src/app/common/models/request-data.model';
import { CustomerService } from './customer.service';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/common/components/confirm-dialog/confirm-dialog.component';
import { LoaderComponent } from '../../common/components/loader/loader.component';
import { TagService } from '../tag/tag.service';
import { AppConstants } from 'src/app/common/app.constants';
import { FilterListItemModel, SearchFilterComponent, SearchParam } from 'src/app/common/components/search-filter/search-filter.component';
import { SelectionModel } from '@angular/cdk/collections';
import { ToolbarService } from 'src/app/common/components/toolbar/toolbar.service';
import { Subscription } from 'rxjs';
import { SegmentService } from '../segment/segment.service';
import { AuthService } from 'src/app/auth/auth.service';
import { FilterService } from 'src/app/common/components/search-filter/filter.service';
import { TagModel } from '../tag/model/tag.model';
import { Router } from '@angular/router';
import { StateManagerService } from 'src/app/common/services/state.manager.service';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { PermissionService } from 'src/app/auth/permission.service';

export declare type NotificationSource = 'option-bar' | 'filter';

@Component({
    selector: 'app-customer',
    templateUrl: './customer.component.html',
    styleUrls: ['./customer.component.scss'],
    standalone: false
})


export class CustomerComponent {


  //customerList: CustomerModel[];
  customerList: CustomerModel[]=[];
  showSearch: boolean = false;
  isLoading = false;
  IsDownloading = false;
  

  // displayedColumns = ['loginid', 'name', 'phone', 'email', 'custtype', 'subscribed', 'active', 'action'];
// Main columns for data rows and second header row
displayedColumns = [
    'selectMenu',
    'select',
    'item',
    'customer',
    'customer_type',
    'mem_since',
    'recency_score',
    'frequency_score',
    'monetary_score',
    'purchase_amount',
    'purchase_counter',
    'rewards',
    'action'
  ];


  pagingData = { length: 0, pageSize: 50, pageIndex: 0 };
  filter: FilterListItemModel[] = []

  // menuOption: FloatingMenuOption = {

  //   add: true,
  //   search: false,
  //   export: true,
  //   more: true
  // }
  isHandset: boolean = false;
  searchColumFilter: SearchParam = { advSearch: [], freeSearch: [] };
  columnSortData: SearchSort[] = [];
  selectedCustomer?: CustomerModel;
  showCustomerSummary: boolean = false;
  tags: any;
  segments: any;
  favoriteFilters: any;
  selection = new SelectionModel<any>(true, []);
  listedCustIds: any[] = [];
  showSelectedCustomers: boolean = false;
  tableDataSource: CustomerModel[] = [];
  selectedCustFilter: any;
  notification?: { message?: string, source?: NotificationSource } = undefined;
  lastUsedRequestData: RequestData = {};
  dataSource = new MatTableDataSource(this.customerList);

  @ViewChild(SearchFilterComponent) filterComponent: SearchFilterComponent | undefined;
  exportList: any;
  permission: any;

  constructor(private breakpointObserver: BreakpointObserver,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private snackBarService: SnackBarService,
    private tagService: TagService,
    private segmentService: SegmentService,
    private authService: AuthService,
    private filterService: FilterService,
    private router: Router,
    private stateManagerService: StateManagerService,
    private permissionService:PermissionService,

  ) {
    this.filter = this.customerService.getFilter();
    this.customerList = [];
    this.permission = permissionService.getPagePermission('customer');


  }


  /**
   * initilaize the device model
   */
  ngOnInit(): void {
    this.breakpointObserver.observe([Breakpoints.Handset]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.isHandset = true;
      } else {
        this.isHandset = false;
      }
    });
    this.isLoading = false; // Set to true on page load
    this.IsDownloading = false;


    //this.loadTag();
    //this.loadSegments();
    this.loadFavoriteFilters();
   // this.loadData();
  }
  /** 
  * Set the datasource
  */
  setDataSource(customerList:CustomerModel[]){
    this.dataSource=new MatTableDataSource(customerList);
    this.dataSource.sort=this.sort;
  }

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('searchFilter') searchFilter!: SearchFilterComponent;
  ngAfterViewInit(): void {

    this.loadPreviousState();
    // this.loadData();
  }

  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {

    var previousState = this.stateManagerService.getState(this.constructor.name);

    if (previousState !== undefined && this.searchFilter != undefined) {

      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex = previousState.state.search.filters.scope.offset / previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize = previousState.state.search.filters.scope.limit;
      }

      if (previousState.state.search.sort != undefined && previousState.state.search.sort.length > 0) {
        this.setSortColumn(previousState.state.search.sort[0].column,previousState.state.search.sort[0].order);
      }

      this.searchColumFilter = JSON.parse(JSON.stringify(previousState.state.search.filters));
      this.searchFilter.setSearchFilter(this.searchColumFilter,true);

    } else {
      this.setSortColumn('first_name','asc');
      this.loadData();
    }

  }

  /**
   * Sets the sort column
   * @param column 
   * @param direction 
   */
  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction});
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
    

  }

  /**
   * loads and set the segment data
   */
  loadFavoriteFilters(): void {
    let user = this.authService.getUser();
    if (user !== undefined) {
      let requestData: RequestData = {
        search: {
          filters: {
            simpleSearch: [], advSearch: [
              { column: "module", operator: "=", value: "customer" },
              { column: "is_favorite", operator: "=", value: 1 },
              { column: "user_id", operator: "=", value: user.id }
            ], scope: { limit: 0, offset: 0 }
          }, sort: []
        }
      };
      let response = this.filterService.getFilter(requestData).subscribe(response => {
        if (response.status == 'SUCCESS') {
          this.favoriteFilters = response.data.filters;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });
    }
  }

  /**
   * loads and set the segment data
   */
  loadSegments(): void {
    let requestData = { "search": { "filters": { "column": [], "scope": { "limit": 1000, "offset": 0 } }, "sort": [{ column: "customer", order: "asc" }] } };
    let response = this.segmentService.getSegmentList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.segments = response.data.segments;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * loads and set the tag data
   */
  loadTag(): void {
    let requestData = { "search": { "filters": { "column": [], "scope": { "limit": 0, "offset": 0 } }, "sort": [{ column: "customer", order: "asc" }] } };
    let response = this.tagService.getTagList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        this.tags = response.data.tags;
      } else {
        let snackBarData: SnackBarData = {
          message: response.message,
          title: 'Failed!!!',
          type: 'error'
        }
        this.snackBarService.openSnackBar(snackBarData);
      }
    });
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;

  }

  /**
   * Creates the column filter conditions based on search criteria
   * if customers are selected and filtering for selected customer add filtered customer ids
   * @returns Filter condions for columns
   */
  getSearchParam(): SearchParam {

    let searchParam = JSON.parse(JSON.stringify(this.searchColumFilter));
    if (this.selectedCustFilter !== undefined) {
      if (searchParam.advSearch !== undefined)
        searchParam.advSearch.push(this.selectedCustFilter);
      else
        searchParam.advSearch = [this.selectedCustFilter];
    }
    return searchParam;

  }

  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: searchParam.advSearch,
          scope: {
            limit: this.pagingData.pageSize,
            offset: (this.pagingData.pageIndex * this.pagingData.pageSize),
          }
        },
        sort: this.getSort(),
      }
    };

    return requestData;
  }

  /**
   * loads the Customer data based on the conditions
   */
  loadData(param?: { requestData?: any, callback?: (value?: any) => void }): void {
    this.isLoading = true; // Show loader

    let requestData: any;
    requestData = (param?.requestData == undefined) ? this.buildRequestData() : param.requestData;
    this.lastUsedRequestData = requestData;
    this.selectedCustomer = undefined;
    let response = this.customerService.getList(requestData).subscribe(response => {
      this.isLoading = false; // Hide loader

      this.processCustDataResponse(response);

      if (response.status == 'SUCCESS') {
        if (this.pagingData.length > 0) {
          this.stateManagerService.setState(this.constructor.name, requestData);

        }
       
        /**  * Call back the function if defined. */
        if (param?.callback !== undefined) param.callback();
      }


      // if (response.status == 'SUCCESS') {
      //   this.customerList = response.data.customers;
      //   this.tableDataSource = this.customerList;
      //   this.pagingData.length = response.data.total_count;
      //   this.listedCustIds.length = 0;
      //   if (this.pagingData.length == 0) {
      //     let snackBarData: SnackBarData = {
      //       message: 'No data found. Please remove or change the filter if any.',
      //       title: 'No Data!!!',
      //       type: 'warn'
      //     }
      //     this.snackBarService.openSnackBar(snackBarData);
      //   } else {
      //     this.listedCustIds = this.customerList.map((cust) => {
      //       return cust.id;
      //     });
      //     this.selectedCustomer = this.customerList[0];
      //   }
      //   /**  * Call back the function if defined. */
      //   if (param?.callback !== undefined) param.callback();
      // } else {
      //   let snackBarData: SnackBarData = {
      //     message: response.message,
      //     title: 'Failed!!!',
      //     type: 'error'
      //   }
      //   this.snackBarService.openSnackBar(snackBarData);
      // }
    });

  }

  /**
   * process the response for customer related api call
   * @param response 
   */
  processCustDataResponse(response: any): void {
    if (response.status == 'SUCCESS') {
      this.customerList = response.data.customers;
      this.setDataSource(response.data.customers );
      this.tableDataSource = this.customerList;
      this.pagingData.length = response.data.total_count;
      this.listedCustIds.length = 0;
      if (this.pagingData.length == 0) {
        let snackBarData: SnackBarData = {
          message: 'No data found.',
          title: 'No Data!!!',
          type: 'warn'
        }
        this.snackBarService.openSnackBar(snackBarData);
      } else {
      // this.stateManagerService.setState(this.constructor.name, requestData);

        this.listedCustIds = this.customerList.map((cust) => {
          return cust.id;
        });
        // this.selectedCustomer = this.customerList[0];
      }
    } else {
      let snackBarData: SnackBarData = {
        message: response.message,
        title: 'Failed!!!',
        type: 'error'
      }
      this.snackBarService.openSnackBar(snackBarData);
    }
  }

  /**
   * On Search toggle button is clicked
   */
  toggleSearch(): void {
    this.showSearch = !this.showSearch
  }

  /**
   * On Add clicked
   * Show the dialog to enter new category
   */
  addClicked(): void {

    this.router.navigate(['customer-edit', 0]);

  }


  exportClicked() {
    let requestData = this.buildRequestData();
    this.IsDownloading = true;

    this.customerService.downloadExcel(requestData)
      .subscribe({
        next:(blob) => {
          // Download logic
          this.IsDownloading = false;
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'customers.csv'; // Adjust filename if needed
          link.click();
          window.URL.revokeObjectURL(url);
          
        },
        error:(error) => {
          // Handle errors
          this.IsDownloading = false;
          let snackBarData: SnackBarData = {
            message: 'Failed to export customer data. Please try later.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);          
          // Display user-friendly error message to the user
        }
      }
      );
  }
  /**
   * 
   * @param event 
   * When page size is changed update paging data
   */
  onePageEvent(event: any): void {

    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();

  }

  /**
 * On edit the item
 * Show the edit dialog
 */
  onEdit(customerInfo: CustomerModel): void {
    this.router.navigate(['customer-edit', customerInfo.id]);

  }

  /**
   * On customer selected
   * @param customerInfo 
   */
  onRowSelected(customerInfo: CustomerModel): void {
    this.selectedCustomer = customerInfo;
    // this.showCustomerSummary = true;
  }



  /**
 * Set the column filter and reaload the daaa
 * @param columFilter 
 */
  onFilterApplied(columFilter: any): void {
    this.searchColumFilter = columFilter;
    this.loadData();

  }

  /**
   * On delete menu item selected
   * Confirm the Customer action and call the api to update
   */
  onDeleteClick(customerInfo: CustomerModel): void {
    const CustomerId = customerInfo.id;
    const message = `Are you sure, you want to delete ` + (customerInfo.first_name + " " + customerInfo.last_name + "?");
    const dialogData = new ConfirmDialogModel("Confirm Deletion", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      console.log(dialogResult);
      if (dialogResult) {
        this.customerService.delete(customerInfo).subscribe({
          next: (response) => {
            if (response.status == 'SUCCESS') {
              // handle success
              let snckBarData: SnackBarData = {
                type: 'success',
                title: 'Saved',
                message: 'The record for ' + customerInfo.first_name + " " + customerInfo.last_name + " has been deleted succesfully."
              }
              this.snackBarService.openSnackBar(snckBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: 'Failed!!!',
                type: 'error'
              }
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: 'Failed to update. Connection to the server failed',
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        });
      }
    });
  }

  /**
   *  Set the sort param and relead the data
   * @param $event sort data
   * {"active": "email","direction": "asc"}
   *   
   */


  /**
   * 
   * @returns True of all rows area selected.
   */
  isAllSelected() {

    const curPageSelectedCusts: number[] = this.getCurPageSelectedCusts();
    return curPageSelectedCusts.length == this.customerList.length;
  }

  /**
   * 
   * @returns Extracts the customer ids those are selected in the current page.
   */
  getCurPageSelectedCusts(): any[] {
    let selectedCustomerIds = this.selection.selected;
    const curPageSelectedCusts: number[] = selectedCustomerIds.filter((element) => this.listedCustIds.includes(element));
    return curPageSelectedCusts;
  }

  /** 
  * Selects all rows if they are not all selected; otherwise clear selection.
  */
  toggleAllRows() {
    if (this.isAllSelected()) {
      let curPageSelectedCusts = this.getCurPageSelectedCusts();
      this.selection.deselect(...curPageSelectedCusts);
      return;
    }
    this.selection.select(...this.listedCustIds);
  }


  /**
   * Show all/selected
   */
  toglleDisplaySelected(): void {
    this.showSelectedCustomers = !this.showSelectedCustomers;
    if (this.showSelectedCustomers) {
      this.pagingData.pageIndex = 0;
      this.selectedCustFilter = { column: 'id', operator: 'in', value: [...this.selection.selected] };
    } else {
      this.selectedCustFilter = undefined;
    }
    this.loadData();

  }

  /**
   * Clear all selctions accroos the pages
   */
  clearAllSelection(): void {
    this.listedCustIds.length = 0;
    this.selection.clear();

  }

  /**
   * When tag is slected in the option bar
   * @param tag 
   */
  onTagSelected(tag: any): void {
    let reqData = this.buildRequestData();
    reqData.search?.filters?.advSearch?.push({ column: "tags", operator: "inset", value: tag.id });
    this.loadData({ requestData: reqData, callback: this.onOptionBarFilterData.bind(this) });
  }

  /**
 * When segment is slected in the option bar
 * @param tag 
 */
  onSegmentSelected(segment: any): void {
    let reqData = this.buildRequestData();
    reqData.search?.filters?.advSearch?.push({ column: "crm_segment_id", operator: "=", value: segment.id });
    this.loadData({ requestData: reqData, callback: this.onOptionBarFilterData.bind(this) });

  }

  onFilterSelected(filter: any): void {
    this.filterComponent?.loadFilterOnDemand(filter);
    // this.onOptionBarFilterData();
  }

  onOptionBarFilterData() {
    // this.notification = { message: "Out of " + this.customerList.length + " " + this.selection.selected.length + " are selected.", source: "option-bar" };
    // let ids = this.customerList.map(cust => {
    //   cust.id;
    // });
    this.selection.clear();
    this.selection.setSelection(...this.listedCustIds);
  }
  /**
   * option bar filter selection has been reset.
   */
  optionBarFilterReset(param?: any): void {
    this.clearAllSelection();
    if (param?.filter) {
      this.filterComponent?.onFilterReset();
      this.filterComponent?.onFilterApply();
    } else {
      this.loadData();
    }
    // let reqData = this.buildRequestData();

  }
  /**
   * Build the selection notification message
   * @returns 
   */
  getSelectionNotificationMsg(): string {
    if (this.selection.selected.length == this.pagingData.length) {
      return "All customers are selected."
    } else {
      return "Out of " + this.pagingData.length + " customers, " + this.selection.selected.length + (this.selection.selected.length == 1 ? " is" : " are") + " selected.";// Click on the 'Select All' button to select all customers."
    }
  }



  /**
   * When tags applied in the action list bar
   * @param tag 
   */
  onTagsApplied(custTags: any[]): void {
    // let tags = "";
    // for (let i = 0; i < custTags.length; i++) {
    //   tags=tags + (tags.trim()==""?"":",")+ custTags[i].id;

    // }
    const tags = custTags.map(TagModel => { return TagModel.id });
    const curPageSelectedCusts: number[] = this.getCurPageSelectedCusts();
    if (curPageSelectedCusts.length > 0) {
      const params = { "customers": curPageSelectedCusts, "tags": tags.join(',') };

      this.customerService.updateCustomerTag(params).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: "Selected customer's tag has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.loadData();
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });


    }
  }


  /**
   * When Segment is applied in the action list bar
   * @param Segment 
   */
  onSegmentApplied(custSegment: any): void {
    const curPageSelectedCusts: number[] = this.getCurPageSelectedCusts();
    if (curPageSelectedCusts.length > 0) {
      const params = { "customers": curPageSelectedCusts, "segment": custSegment.id };

      this.customerService.updateCustomerSegment(params).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: "Selected customer's segment has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.loadData();
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });


    }

  }



  onRedirect(customerInfo: CustomerModel): void {
    // Replace 'customer_id_value' with the actual customer ID you want to pass
    const customerId = customerInfo.id;
    console.log('Redirecting to orders for customer ID:', customerInfo.id);


    // Navigate to the orders page with the customer ID as a query parameter
    this.router.navigate(['order',customerId]);

  }
  sortData($event: any): void {
    var sd=this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }
  resetState() {
    // Call the resetState function
    this.stateManagerService.resetState();
  }

  // Copy Email Address
  copyToClipboard(text: string): void {
    navigator.clipboard.writeText(text).then(() => {
      console.log('Email address copied to clipboard:', text);
      // Optionally, you can display a success message
      let snckBarData: SnackBarData = {
        type: 'success',
        title: 'Copied',
        message: 'Email ' + text + " has been copied."
      }
      this.snackBarService.openSnackBar(snckBarData);
    }).catch(err => {
      console.error('Could not copy text: ', err);
    });
  }

  
}
