import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppConstants } from 'src/app/common/app.constants';
import { LoginService } from './login.service';
import { environment } from 'src/environments/environment';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: false
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  userId: string = "";
  password: string = "";
  version: string;
  buildNo:any;

  constructor(private builder: FormBuilder,
    private router: Router, private loginService: LoginService,
    private snackBarService: SnackBarService
  ) {
    sessionStorage.clear();
    this.loginForm = this.builder.group({
      user_id: this.builder.control('', Validators.required),
      password: this.builder.control('', Validators.required)
    });
    this.version=environment.version;
    this.buildNo=environment.buildNo;
  }

  ngOnInit(): void { 
    this.authenticate();
  }

  /**
   * Validate the application credentials and navigate to user login
   */
  authenticate() {
    
      let authData = {
        user_name: environment.userId,
        password: environment.password,
        apikey: environment.appKey
      };

      this.loginService.authenticate(authData).subscribe(
        response => {
          if (response.status == 'SUCCESS') {
            let token = response.token;
            sessionStorage.setItem(AppConstants.SESSION_VAR_TOKEN, token);
          } else {
            let snackBarData: SnackBarData = {
              title: "Failed",
              message: response.message,
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        }
      );
    
  }

  /**
   * Validate the user credentials and navigate to dashboard
   */
  proceedUserLogin() {
    if (this.loginForm.valid) {

      let loginData = {
        user_name: this.loginForm.get("user_id")?.value,
        password: this.loginForm.get("password")?.value
      };

      this.loginService.userLogin(loginData).subscribe(
        response => {
          if (response.status == 'SUCCESS') {
            let curUser = response.data;
            let token = response.token;
            sessionStorage.setItem(AppConstants.SESSION_VAR_USER, JSON.stringify(curUser));
            sessionStorage.setItem(AppConstants.SESSION_VAR_TOKEN, token);
            this.router.navigate(['customer']);
          } else {
            let snackBarData: SnackBarData = {
              title: "Login Failed.",
              message: response.message,
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        }
      );
    }
  }
}
