import { Component, Inject, ViewEncapsulation, NgModule } from '@angular/core';
import { FormBuilder,FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LuckyDrawSettingModel } from '../../model/lucky-draw-settings.model';
import { LuckyDrawSettingService } from '../../lucky-draw-settings.service';
import { SnackBarData } from 'src/app/common/components/snackbar/model/snackbar.model';
import { SnackbarService } from 'src/app/common/services/snackbar.service';
import { SnackBarService } from 'src/app/common/components/snackbar/snackbar.service';

@Component({
    selector: 'app-luckdraw-edit-dialog',
    templateUrl: './luckdraw-edit-dialog.component.html',
    styleUrls: ['./luckdraw-edit-dialog.component.scss'],
    standalone: false
})
export class LuckyDrawEditDialogComponent {

  title: string;
  editForm!: FormGroup;
  luckyDrawInfo: LuckyDrawSettingModel;

  constructor(private builder: FormBuilder,
    public dialogRef: MatDialogRef<LuckyDrawEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public item: any,
    private luckDrawService: LuckyDrawSettingService, private snackBarService: SnackBarService) {
      this.luckyDrawInfo = JSON.parse(JSON.stringify(item));
      this.editForm = this.builder.group({
        code: this.builder.control(this.luckyDrawInfo.code, [Validators.required, Validators.maxLength(50)]),
        name: this.builder.control(this.luckyDrawInfo.name, [Validators.required]),
        // is_valid: this.builder.control(this.luckyDrawInfo.is_active, [])
      });
    this.title = this.getTitle();
    
  }

  /**
   * On save
   * if the details are valid call the api to update the changes.
   */
  save(): void {

    if (this.editForm.valid) {
      // this.rewardInfo = this.editForm.value;
      Object.assign(this.luckyDrawInfo, this.editForm.value);
      this.luckDrawService.update(this.luckyDrawInfo).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            this.dialogRef.close(this.luckyDrawInfo);
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: 'The record for ' + this.luckyDrawInfo.name +  " has been updated succesfully."
            }
            this.snackBarService.openSnackBar(snckBarData);
          } else {
            let snackBarData:SnackBarData={
              message:response.message,
              title:'Failed!!!',
              type:'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          // handle error
          let snackBarData:SnackBarData={
            message:'Failed to update. Connection to the server failed',
            title:'Failed!!!',
            type:'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });

    }
  }

  /**
   * Close the edit dialog
   */
  cancel(): void {
    this.dialogRef.close();
  }
  /**
   * 
   * @returns  the title for the dialog
   * sine the same dialog is used for edit and new, dynamically decide the title
   */
  getTitle(): string {
    let title = (this.item.id == undefined) ? "New" : "Editing : " + this.item.name;
    return title;
  }
}
