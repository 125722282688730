import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
    selector: 'app-lucky-draw-winner-publish-dialog',
    templateUrl: './lucky-draw-winner-publish-dialog.component.html',
    styleUrl: './lucky-draw-winner-publish-dialog.component.scss',
    standalone: false
})
export class LuckyDrawWinnerPublishDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<LuckyDrawWinnerPublishDialogComponent>,
    @Inject(MAT_DIALOG_DATA)  
 public data:  { message: string; showActionButton: boolean;title:string}) {}

 onConfirm(): void {
  this.dialogRef.close(true);
}

onCancel(): void {
  this.dialogRef.close(false);

}
}
