import { Component, ViewChild } from "@angular/core";
import { LuckyDrawWinnerService } from "./lucky-draw-winner.service";
import { MatTableDataSource } from "@angular/material/table";
import { SearchFilterComponent } from "src/app/common/components/search-filter/search-filter.component";
import { LuckyDrawWinnerModel } from "../lucky-draw/model/lucky-draw.model";
import { MatSort, SortDirection } from "@angular/material/sort";
import { StateManagerService } from "src/app/common/services/state.manager.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import {
  ColumnFilter,
  RequestData,
  SearchSort,
} from "src/app/common/models/request-data.model";
import {
  FilterListItemModel,
  SearchParam,
} from "src/app/common/components/search-filter/search-filter.component";
import { RewardService } from "../reward/reward.service";
import { SelectionModel } from "@angular/cdk/collections";
import { PermissionService } from "src/app/auth/permission.service";
import { ActivatedRoute, Router } from "@angular/router";
import { LuckyDrawService } from "../lucky-draw/lucky-draw.service";
import { MatDialog } from "@angular/material/dialog";
import { LuckyDrawWinnerPublishDialogComponent } from "./lucky-draw-winner-publish-dialog/lucky-draw-winner-publish-dialog.component";
import { ConfirmDialogComponent, ConfirmDialogModel } from "src/app/common/components/confirm-dialog/confirm-dialog.component";


@Component({
    selector: "app-lucky-draw-winner",
    templateUrl: "./lucky-draw-winner.component.html",
    styleUrl: "./lucky-draw-winner.component.scss",
    standalone: false
})
export class LuckyDrawWinnerComponent {
  winnerList: LuckyDrawWinnerModel[] = [];
  drawWinnereData: any;
  data: any;
  isLoading = true;
  IsDownloading = false;
  selection = new SelectionModel<any>(true, []);
  listedCustIds: any[] = [];
  showSelectedCustomers: boolean = false;
  selectedCustFilter: any;
  lastUsedRequestData: RequestData = {};
  selectedWinnerFilter: any;
  selectedCustomer?: LuckyDrawWinnerModel;
  hideAdvancedFilter: boolean = false;
  defaultDrawWinnerFilter = [{ column: "crm_lucky_draw_id", operator: "=", value: "" }];
  filter: FilterListItemModel[] = [
    {
      title: "Customer",
      column: "customer_name",
    },
    {
      title: "Email",
      column: "email",
      type: "string",
      operators: [{ title: "is", operator: "=" },
        { title: "like", operator: "like" },
      ],
    },
    {
      title: "Phone",
      column: "phone",
      type: "string",
      operators: [
        { title: "is", operator: "=" },
        { title: "like", operator: "like" },
      ],
    },
    {
      title: "Receipt",
      column: "receipt_number",
    },
    {
      title: "Prize",
      column: "reward_id",
      // column: "reward_eligibility",
      type: "list",
      values: [],
      
    },
    // {
    //   title: "Status",
    //   column: "is_valid",
    //   type: "boolean",
    //   values: [
    //     { title: "Valid", value: "1" },
    //     { title: "Not Valid", value: "0" },
    //   ],
    // },
  ];
  // dataSource = new MatTableDataSource<any>();
  dataSource = new MatTableDataSource(this.winnerList);
  @ViewChild(SearchFilterComponent) filterComponent:
    | SearchFilterComponent
    | undefined;
  selectedItem?: LuckyDrawWinnerModel;
  searchColumFilter: ColumnFilter[] = [];
  columnSortData: SearchSort[] = [];
  displayedColumns = [
    "selectMenu",
    "select",
    "item",
    "customer_name",
    "phone",
    "receipt_number",
    "reward_name",
    "entry_time",
    // "is_valid",
  ];
  pagingData = { length: 0, pageSize: 50, pageIndex: 0 };
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild("searchFilter") searchFilter!: SearchFilterComponent;
  permission: any;
  drawWinnerId: any;
  rewardList: any;
  drawDetails: any;
  publishValue: any;
  drawName: any;

  constructor(
    private LuckyDrawWinnerService: LuckyDrawWinnerService,
    private stateManagerService: StateManagerService,
    private snackBarService: SnackBarService,
    private itemService: RewardService,
    private permissionService: PermissionService,
    private route: ActivatedRoute,
    private router: Router,
    private luckyDrawService: LuckyDrawService,
    private dialog: MatDialog,
    
  ) {
    this.permission = permissionService.getPagePermission("draw-winner");
  }

  ngOnInit() {
    // this.loadData();
    this.isLoading = false;
    this.IsDownloading = false;
    this.loadRewardData();
    this.drawWinnerId = this.route.snapshot.params['drawId'];
    if (this.drawWinnerId != '' && this.drawWinnerId != undefined) {
      this.hideAdvancedFilter = true;
      this.defaultDrawWinnerFilter[0].value = this.drawWinnerId;
  
    } else {
     
      this.hideAdvancedFilter = false;
    }
    this.getDraw();
  }


  onFilterApplied(columFilter: ColumnFilter[]): void {
    this.searchColumFilter = columFilter;
    this.loadData();
  }

  // loadData(): any {
  //   this.isLoading = true;
  //   let requestData = this.buildRequestData();
  //   this.LuckyDrawWinnerService.getLuckyDrawWinnerDetails(requestData).subscribe({
  //     next: (response: any) => {
  //       if (response.status === 'SUCCESS') {
  //         this.setDataSource(response.data.winners);
  //         this.pagingData.length = response.data.total_count;
  //         console.log('Winners Data:', this.dataSource.data);
  //         if (this.pagingData.length == 0) {
  //           let snackBarData: SnackBarData = {
  //             message: 'No data found. Please remove or change the filter if any.',
  //             title: 'No Data!!!',
  //             type: 'warn'
  //           }
  //           this.snackBarService.openSnackBar(snackBarData);
  //         } else {
  //           this.stateManagerService.setState(this.constructor.name,requestData );
  //         }
  //       }  else {
  //         let snackBarData: SnackBarData = {
  //           message: response.message,
  //           title: 'Failed!!!',
  //           type: 'error'
  //         }
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //       this.isLoading = false;
  //     }

  //   });
  // }

  setDataSource(itemList: LuckyDrawWinnerModel[]) {
    this.dataSource = new MatTableDataSource(itemList);
    this.dataSource.sort = this.sort;
  }

  ngAfterViewInit(): void {
    if (this.drawWinnerId != '' && this.drawWinnerId != undefined) {

     
      this.searchFilter.setColumnFilter(this.defaultDrawWinnerFilter);
    }
    this.loadPreviousState();
    // this.loadData();
  }

  /**
   * loads the previous state if exists
   */
  loadPreviousState(): void {
    var previousState = this.stateManagerService.getState(
      this.constructor.name
    );

    if (previousState !== undefined && this.searchFilter != undefined) {
      if (previousState.state.search.filters.scope) {
        this.pagingData.pageIndex =
          previousState.state.search.filters.scope.offset /
          previousState.state.search.filters.scope.limit;
        this.pagingData.pageSize =
          previousState.state.search.filters.scope.limit;
      }

      if (
        previousState.state.search.sort != undefined &&
        previousState.state.search.sort.length > 0
      ) {
        this.setSortColumn(
          previousState.state.search.sort[0].column,
          previousState.state.search.sort[0].order
        );
      }

      this.searchColumFilter = JSON.parse(
        JSON.stringify(previousState.state.search.filters)
      );
      this.searchFilter.setSearchFilter(this.searchColumFilter, true);
    } else {
      this.setSortColumn("customer_name", "asc");
      this.loadData();
    }
  }

  setSortColumn(column: string, direction: SortDirection): void {
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: column, order: direction });
    this.sort.sort({
      id: column,
      start: direction,
      disableClear: false,
    });

    // this.sort.active=column;
    // this.sort.direction=direction;
  }

  onePageEvent(event: any): void {
    this.pagingData.pageIndex = event.pageIndex;
    this.pagingData.pageSize = event.pageSize;

    this.loadData();
  }

  /**
   * Builds the request data to be send to API
   * @returns Request Data
   */
  buildRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: [...(searchParam.advSearch || []), {
            column: "is_valid",
            operator: "=",
            value: "1",
          }],
          scope: {
            limit: this.pagingData.pageSize,
            offset: this.pagingData.pageIndex * this.pagingData.pageSize,
          },
        },
        sort: this.getSort(),
      },
    };

    return requestData;
  }

  getSearchParam(): SearchParam {
    let searchParam = JSON.parse(JSON.stringify(this.searchColumFilter));
    if (this.selectedWinnerFilter !== undefined) {
      if (searchParam.advSearch !== undefined)
        searchParam.advSearch.push(this.selectedWinnerFilter);
      else searchParam.advSearch = [this.selectedWinnerFilter];
    }
    return searchParam;
  }

  /**
   * Create sort order
   * @returns sort criteria
   */
  getSort(): SearchSort[] {
    return this.columnSortData;
  }

  buildRewardRequestData(): RequestData {
    let searchParam = this.getSearchParam();
    let requestData: RequestData = {
      search: {
        filters: {
          simpleSearch: searchParam.freeSearch,
          advSearch: [
            {
              column: "reward_eligibility",
              operator: "=",
              value: "2",
            },
            {
              column: "status",
              operator: "in",
              value: ["1"],
              type: "list",
            },
          ],
          scope: {
            limit: 99999,
            offset: 0,
          },
        },
        sort: this.getSort(),
      },
    };

    return requestData;
  }
  /**to load the reward data */
  loadRewardData(): void {
    this.isLoading = true;
    let requestData = this.buildRewardRequestData();
    let response = this.itemService
      .getList(requestData)
      .subscribe((response) => {
        if (response.status == "SUCCESS") {
          this.rewardList = response.data.rewards;
          this.filter[4].values = this.rewardList.map((cat: any) => {
            return { title: cat.name, value: cat.id };
          });
          // this.setDataSource(response.data.rewards );
        
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
        this.isLoading = false;
      });
  }

  /**
   *  Set the sort param and relead the data
   *
   */
  sortData($event: any): void {
    var sd = this.sort;
    this.columnSortData.length = 0;
    this.columnSortData.push({ column: sd.active, order: sd.direction });
    this.loadData();
  }

  /**
   *
   * @returns Extracts the customer ids those are selected in the current page.
   */
  getCurPageSelectedCusts(): any[] {
    let selectedCustomerIds = this.selection.selected;
    const curPageSelectedCusts: number[] = selectedCustomerIds.filter(
      (element) => this.listedCustIds.includes(element)
    );
    return curPageSelectedCusts;
  }

  /**
   *
   * @returns True of all rows area selected.
   */
  isAllSelected() {
    const curPageSelectedCusts: number[] = this.getCurPageSelectedCusts();
    return curPageSelectedCusts.length == this.winnerList.length;
  }

  /**
   * Selects all rows if they are not all selected; otherwise clear selection.
   */
  toggleAllRows() {
    if (this.isAllSelected()) {
      let curPageSelectedCusts = this.getCurPageSelectedCusts();
      this.selection.deselect(...curPageSelectedCusts);

      return;
    }
    this.selection.select(...this.listedCustIds);
  }

  /**
   * Build the selection notification message
   * @returns
   */
  getSelectionNotificationMsg(): string {
    if (this.selection.selected.length == this.pagingData.length) {
      return "All winners are selected.";
    } else {
      return (
        "Out of " +
        this.pagingData.length +
        " winners, " +
        this.selection.selected.length +
        (this.selection.selected.length == 1 ? " is" : " are") +
        " selected."
      ); // Click on the 'Select All' button to select all customers."
    }
  }

  /**
   * Show all/selected
   */
  toglleDisplaySelected(): void {
    this.showSelectedCustomers = !this.showSelectedCustomers;
    if (this.showSelectedCustomers) {
      this.pagingData.pageIndex = 0;
      this.selectedWinnerFilter = {
        column: "id",
        operator: "in",
        value: [...this.selection.selected],
      };
    } else {
      this.selectedWinnerFilter = undefined;
    }
    this.loadData();
  }

  /**
   * Clear all selctions accroos the pages
   */
  clearAllSelection(): void {
    this.listedCustIds.length = 0;
    this.selection.clear();
  }

  /**
   * loads the winners data based on the conditions
   */
  processCustDataResponse(response: any): void {
    if (response.status == "SUCCESS") {
      this.winnerList = response.data.winners;
      this.setDataSource(response.data.winners);
      // this.tableDataSource = this.itemList;
      this.pagingData.length = response.data.total_count;
      this.listedCustIds.length = 0;
      if (this.pagingData.length == 0) {
        let snackBarData: SnackBarData = {
          message: "No data found.",
          title: "No Data!!!",
          type: "warn",
        };
        this.snackBarService.openSnackBar(snackBarData);
      } else {
        // this.stateManagerService.setState(this.constructor.name, requestData);

        this.listedCustIds = this.winnerList.map((cust) => {
          return cust.id;
        });
        
        
      }
    } else {
      let snackBarData: SnackBarData = {
        message: response.message,
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
    }
  }

  /**
   * loads the Customer data based on the conditions
   */
  loadData(param?: {
    requestData?: any;
    callback?: (value?: any) => void;
  }): void {
    this.isLoading = true; // Show loader

    let requestData: any;
    requestData =
      param?.requestData == undefined
        ? this.buildRequestData()
        : param.requestData;
    this.lastUsedRequestData = requestData;
    this.selectedCustomer = undefined;
    let response = this.LuckyDrawWinnerService.getLuckyDrawWinnerDetails(
      requestData
    ).subscribe((response) => {
      this.isLoading = false; // Hide loader

      this.processCustDataResponse(response);

      if (response.status == "SUCCESS") {
        if (this.pagingData.length > 0) {
          this.stateManagerService.setState(this.constructor.name, requestData);
        }

        /**  * Call back the function if defined. */
        if (param?.callback !== undefined) param.callback();
      }
    });
  }
  
  exportClicked() {
    let requestData = this.buildRequestData();
    this.IsDownloading = true;

    this.LuckyDrawWinnerService.downloadExcel(requestData).subscribe({
      next: (blob) => {
        // Download logic
        this.IsDownloading = false;
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "winners.csv"; // Adjust filename if needed
        link.click();
        window.URL.revokeObjectURL(url);
      },
      error: (error) => {
        // Handle errors
        this.IsDownloading = false;
        let snackBarData: SnackBarData = {
          message: "Failed to update. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
        // Display user-friendly error message to the user
      },
    });
  }

/**to include or exclude the  selected winners  */
  onStatusApplied(status:any): void {
    const curPageSelectedCusts: number[] = this.getCurPageSelectedCusts();
    if (curPageSelectedCusts.length > 0) {
      // const params = { "id": curPageSelectedCusts,"is_valid":0};
      const params = curPageSelectedCusts.map(id => ({
        id: id,
        is_valid: status
      }));
  

      this.LuckyDrawWinnerService.updateWinnerStatus(params).subscribe({
        next: (response) => {
          if (response.status == 'SUCCESS') {
            // handle success
            let snckBarData: SnackBarData = {
              type: 'success',
              title: 'Saved',
              message: `Selected winners has been ${status === 1 ? 'included' : 'excluded'} succesfully.`
            }
            this.snackBarService.openSnackBar(snckBarData);
            this.loadData();
          } else {
            let snackBarData: SnackBarData = {
              message: response.message,
              title: 'Failed!!!',
              type: 'error'
            }
            this.snackBarService.openSnackBar(snackBarData);
          }
        },
        error: (error) => {
          let snackBarData: SnackBarData = {
            message: 'Failed to update. Connection to the server failed.',
            title: 'Failed!!!',
            type: 'error'
          }
          this.snackBarService.openSnackBar(snackBarData);
        }
      });


    }
    
  }

  navBack(): void {
    this.router.navigate(["/draw"]);
  }


/**
 * to the draw details
 */
  getDraw(): void {
    let requestData = {"search":{"filters":{"simpleSearch":[],"advSearch":[{"column":"id","operator":"=","value":this.drawWinnerId}],"scope":{"limit":1,"offset":0}},"sort":[{"column":"name","order":"asc"}]}};
    let response = this.luckyDrawService.getList(requestData).subscribe(response => {
      if (response.status == 'SUCCESS') {
        //this.itemList = response.data.rewards;
        // this.setDataSource(response.data.ldHdr);
        this.drawDetails=response.data.ld[0];
        this.publishValue = this.drawDetails.is_published;
        this.drawName=this.drawDetails.name;

        if( this.publishValue==2){
          setTimeout(() => {
            this.getDraw();
          }, 3000); 

        }
       
        
      } else {
        
      }
    
    });

  }

  /**
   * For lucky draw publish API
   * @param draw 
   */
  // publish(){
  //   this.luckyDrawService.publishDraw(this.drawWinnerId).subscribe({
  //     next: (response) => {
  //       if (response.status === "SUCCESS") {
  //         let snackBarData: SnackBarData = {
  //           type: "success",
  //           title: "Published",
  //           message:
  //             "The Lucky Draw was successfully published.",
  //         };
  //         this.getDraw();
  //         this.snackBarService.openSnackBar(snackBarData);
        
  //       } else {
  //         let snackBarData: SnackBarData = {
  //           message: response.message,
  //           title: "Failed!!!",
  //           type: "error",
  //         };
  //         this.snackBarService.openSnackBar(snackBarData);
  //       }
  //     },
  //     error: (error) => {
  //       let snackBarData: SnackBarData = {
  //         message: "Failed to Publish.",
  //         title: "Failed!!!",
  //         type: "error",
  //       };
  //       this.snackBarService.openSnackBar(snackBarData);
  //     },
  //   });
  // }
 
  /**
   * to show the dialog box when click on publish button
   */
  // publishWinner():void{
  //   const dialogRef = this.dialog.open(LuckyDrawWinnerPublishDialogComponent, {
  //     width: '800px',
  //     data: { message: 'Publishing the prizes to the winners is a final action that cannot be undone. It may take some time and once it is finished the status will change to Published. Are you sure you want to proceed?',
  //       showActionButton: true,
  //       title:'Publish Winners'
  //      } // Passing the message here
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       // Call the function to process the winner
  //       this.publish();
  //     }
  //   });
  // }
  
  /**
   * For lucky draw publish API
   * @param draw 
   */
  publish(){
    const message = `Publishing the prizes to the winners is a final action that cannot be undone. Are you sure you want to proceed?`;
    const dialogData = new ConfirmDialogModel("Publishing Prizes!", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        this.luckyDrawService.publishDraw(this.drawWinnerId).subscribe({
          next: (response) => {
            if (response.status === "SUCCESS") {
              let snackBarData: SnackBarData = {
                type: "success",
                title: "Published",
                message:
                  "The Lucky Draw was successfully Published.",
              };
              this.snackBarService.openSnackBar(snackBarData);
              this.loadData();
            } else {
              let snackBarData: SnackBarData = {
                message: response.message,
                title: "Failed!!!",
                type: "error",
              };
              this.snackBarService.openSnackBar(snackBarData);
            }
          },
          error: (error) => {
            let snackBarData: SnackBarData = {
              message: "Failed to Publish.",
              title: "Failed!!!",
              type: "error",
            };
            this.snackBarService.openSnackBar(snackBarData);
          },
        });
      }
    });
  }
  
  /**
   * to show the dialog box when click on published button
   */
  onPublished():void{
    // const dialogRef = this.dialog.open(LuckyDrawWinnerPublishDialogComponent, {
    //   width: '400px',
    //   data: { message: 'Winners are already published.', title:'Publish Winners' } 
    // });
    const message = `Winners are already published.`;
    const dialogData = new ConfirmDialogModel("Publishing Prizes!", message);
    dialogData.showNoButton = false;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }

  
  /**
   * to show the dialog box when click on publishing button
   */
  onPublishing():void{
    // const dialogRef = this.dialog.open(LuckyDrawWinnerPublishDialogComponent, {
    //   width: '400px',
    //   data: { message: 'Publisheing the winner.', title:'Publish Winners' } 
    // });
    const message = `Publisheing the winner.`;
    const dialogData = new ConfirmDialogModel("Publishing Prizes!", message);
    dialogData.showNoButton = false;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
  }
  
}
