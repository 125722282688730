import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  BillDiscountAmountModel,
  BillDiscountItemModel,
  SaleItemDiscountModel,
  BuyXGetYDicountModel as BuyXGetYDicountModel,
  LuckyDrawSettingModel,
  CustomerFilter,
  SaleChannels, AccessMode
} from "../model/lucky-draw-settings.model";
import { LuckyDrawSettingService } from "../lucky-draw-settings.service";
import { SnackBarData } from "src/app/common/components/snackbar/model/snackbar.model";
import { SnackBarService } from "src/app/common/components/snackbar/snackbar.service";
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { DirtyCheck } from "src/app/common/guards/form-deactivate.guard";
import { SegmentService } from "../../segment/segment.service";
import { RewardCategoryService } from "../../reward-category/reward-category.service";
import { LuckyDrawSegmentEditDialogComponent } from "../dialogs/luckydraw-segment-edit-dialog/luckydraw-segment-edit-dialog.component";
import { LuckyDrawProductBrowseDialogComponent } from "../dialogs/luckydraw-product-browse-dialog/luckydraw-product-browse-dialog.component";
import { LuckyDrawProductItemsDialogComponent } from "../dialogs/luckydraw-product-items-dialog/luckydraw-product-items-dialog.component";
import { DiscountModel } from "../../discount/model/discount.model";
import { DatePipe } from "@angular/common";
import { forkJoin } from "rxjs";
import { LuckyDrawImageUploadComponent } from "../dialogs/image-upload/image-upload.component";
import { environment } from "src/environments/environment";
import { getMatIconFailedToSanitizeLiteralError } from "@angular/material/icon";
import { CustomerService } from "../../customer/customer.service";
import { LuckyDrawCustFilterDialogComponent } from "../dialogs/luckdraw-cust-filter-dialog/luckydraw-cust-filter-dialog.component";
import { trigger } from "@angular/animations";
import { LuckyDrawProductsDialogComponent } from "../dialogs/luckydraw-products-dialog/luckydraw-products-dialog.component";
import { formatDate } from '@angular/common';
import { ConfirmDialogComponent, ConfirmDialogModel } from "src/app/common/components/confirm-dialog/confirm-dialog.component";
import { SystemSettingsService } from "src/app/common/services/system-settings.service";
import * as moment from "moment";
import { HtmlEditComponent } from "../components/html-edit/html-edit.component";
import { PermissionService } from "src/app/auth/permission.service";

import Quill from 'quill';
import QuillResizeModule from '@botom/quill-resize-module';
import { CustomOption } from "ngx-quill";
Quill.register("modules/resize", QuillResizeModule);

@Component({
    selector: "app-lucky-draw-setting-edit",
    templateUrl: "./lucky-draw-settings-edit.component.html",
    styleUrls: ["./lucky-draw-settings-edit.component.scss"],
    providers: [
        DatePipe, // Add DatePipe to providers
    ],
    animations: [
        trigger("if", [
        // ... animation states and styles
        ]),
    ],
    standalone: false
})
export class LuckyDrawSettingEditComponent implements DirtyCheck, OnInit {
  static readonly CUSTOMER_ELIGIBILITY_ALL = 1;
  static readonly CUSTOMER_ELIGIBILITY_FILTER = 2;

  title: string = "New ";
  editForm!: FormGroup;
  currentDate: Date = new Date(); // Initialize it with the current date.
  isInsertMode: boolean = true; // Set this to false when updating a record.
  isAutoDistribute: boolean = false;
  value!: number;
  activeTab: number = 0;
  radioButtonValue!: string;
  duplicateItems: any[] = [];
  @ViewChild("fileInput") fileInput!: ElementRef;

  luckyDrawInfoModel: LuckyDrawSettingModel = {
    id: 0,
    code: "",
    name: "",
    description: "",
    short_description: "",
    entry_url: "",
    pos_receipt_text: "",
    oo_receipt_text: "",
    tc_info: "",
    privacy_policy: "",
    start_date: null,
    end_date: null,
    extra_info: {
      channel: undefined,
      customer: 2,
      min_order_amount: 0
    },
    is_deleted: 0,
    is_valid: 1,
    is_published: 0,
    brevo_template_id: null
  };

 
  publishOn!: Date;
  endDate!: Date;
  selectedValidityOption!: string;



  isFormDirty: boolean = false;
  discountList: any;
  segmentList: any;
  rewardCategoryList: any[] = [];
  selectedEventOption: string = "1";
  mailTemplateList: any;
  productList: any;
  itemCategoryList: any;
  isDropdownOpen: boolean = false;
  assetsUrl: string;
  refreshTime: any;
  systemEvent: any;
  selectedSystemEvent: any;
  status: any;
  isStatusZero!: boolean;
  isEmptyStatus!: boolean;
  isStatusNotOneOrTwo: boolean = true; // Default value, adjust as needed
  isNewRecord!: boolean;
  filteredCategoryList: any;
  showRadioBtns!: boolean;
  enabledRewards!: number[];
  dateFormat: string;
  allowPublishManually: boolean = false;
  channelSelected: { title: string; value: number; } | undefined;
  displayTitle: any;
  editorTcData: any;
  editorPrivacyData: any;
  editorData: any;
  permission: any;

  editorConfig = {
    toolbar: [
      ['bold', 'italic', 'underline'],        // toggled buttons
      // [{ 'header': 1 }, { 'header': 2 }],     // custom button values
      // [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      // [{ 'color': [] }, { 'background': [] }],  // Text color and background color
      [{ 'align': [] }],
      // ['link', 'image']                       // link and image
    ]
  };

  customOptions: CustomOption[] = [{
    import: 'attributors/style/align',
    whitelist: ['left', 'center', 'right']
    },
  ];

  toggleDropdown() {
    this.isDropdownOpen = !this.isDropdownOpen;
  }

  private uploadedFileName: string = "";

  constructor(
    private router: Router,
    private builder: FormBuilder,
    private route: ActivatedRoute,
    public luckDrawService: LuckyDrawSettingService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    private segmentService: SegmentService,
    private rewardCategoryService: RewardCategoryService,
    private customerService: CustomerService,
    private datePipe: DatePipe,
    private systemSettingService: SystemSettingsService,
    private permissionService: PermissionService

  ) {
    this.assetsUrl = environment.assetsUrl;
    this.dateFormat = systemSettingService.getDefaultDateFormat();
    this.permission = permissionService.getPagePermission('draw-setting-edit');


  }


  ngOnInit(): void {
    this.loadMailTemplateList();
    let rewardId = this.route.snapshot.paramMap.get("id");
    this.editForm = this.builder.group({
      code: [
        this.luckyDrawInfoModel.code,
        [Validators.required, Validators.maxLength(50)],
      ],
      name: [this.luckyDrawInfoModel.name, [Validators.required]],
      description: [this.luckyDrawInfoModel.description,[]],
      short_description: [this.luckyDrawInfoModel.short_description,[]],
      start_date: [this.luckyDrawInfoModel.start_date, [Validators.required]],
      end_date: [this.luckyDrawInfoModel.end_date, [Validators.required]],
      is_valid: [this.luckyDrawInfoModel.is_valid, [Validators.required]],
      pos_receipt_text: [this.luckyDrawInfoModel.pos_receipt_text, []],
      oo_receipt_text: [this.luckyDrawInfoModel.oo_receipt_text, []],
      target_audience: [this.luckyDrawInfoModel.extra_info.customer, []],
      target_channel: [this.luckyDrawInfoModel.extra_info.channel, []],
      qualified_spend: [this.luckyDrawInfoModel.extra_info.min_order_amount,[]],
      brevo_template_id: [
        this.luckyDrawInfoModel.brevo_template_id,
        [Validators.required],
      ],
    });

    this.editForm.valueChanges.subscribe(() => {
      // this.isFormDirty = true;
      
      //this.isFormDirty = !this.editForm.pristine
      if (this.status !== 0){
        this.isFormDirty = false;

      }else{
        this.isFormDirty = !this.editForm.pristine
      }
    });

    if (rewardId !== null && parseInt(rewardId) !== 0) {
      this.loadSavedLuckDraw(rewardId);
    }
  }

  /**
  * Sets the title
  * @returns the title based on mode new/editing
  */
  getTitle(): string {
    let title =
      this.luckyDrawInfoModel.id == 0
        ? "New Draw"
        : "Editing: " + this.luckyDrawInfoModel.name;
    return title;
  }

  /**
   * Date should be equal or greater than current date
   * @returns 
   */
  isDateInFuture() {
    return this.publishOn >= this.currentDate;
  }

  isValidEndDate(){
    return this.endDate >= this.publishOn;
  }



  isDirty(): boolean {
    return (this.permission.can_edit && this.isFormDirty);
  }

  // Format a date using DatePipe
  formatDate(date: Date): string {
    return this.datePipe.transform(date, 'dd-MM-yyyy') || '';
  }

  /**
   * Returns the display label for segment
   */
  getSegmentLabel(id: number): string {
    let segment = this.segmentList.find((s: any) => s.id == id);
    return segment.code;
  }

  navBack(): void {
    this.router.navigate(["/draw-setting"]);
  }

  publishLuckyDraw(rewardId: any): void {
    let response = this.luckDrawService.publishReward(rewardId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Success",
            type: "success",
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.allowPublishManually = false;
          this.isFormDirty = false;
          this.router.navigate(['lucky-draw']);

          // Set isStatusNotOneOrTwo based on your condition
        } else if (response.status == "NO_DATA_FOUND") {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);

        }
        else {
          let snackBarData: SnackBarData = {
            message: 'This lucky draw can not be published now.',
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (_error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to publish. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  /**
   * Load the reaward info from api and set to form
   *
   * @param rewardId
   */

  loadSavedLuckDraw(rewardId: any): void {
    let response = this.luckDrawService.getLuckyDrawById(rewardId).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          response = response.data.ldHdr
          // this.status = response.data.reward[0].status;
          this.status = response[0].is_published;
          //temporarly commented
          // this.allowPublishManually = response.data.allow_publish_manually;
          this.allowPublishManually = true;
          this.isStatusZero = this.status === 0; // Track if status is zero

          this.buildLuckDrawFromResponse(response);

          // Show notification if status is not '0'
          if (this.status !== 0) {
            const notificationElement = document.getElementById("notification");
            if (notificationElement) {
              notificationElement.style.display = "block";
            }
          }

          // Set isStatusNotOneOrTwo based on your condition
          this.isStatusNotOneOrTwo = this.status !== 1 && this.status !== 2;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message:
            "Failed to get data from server. Connection to the server failed.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }


  /**
   * Set the variable from response
   * @param response
   */
  buildLuckDrawFromResponse(response: any): void {
    let luckyDrawInfo = response[0];
    this.luckyDrawInfoModel.id = luckyDrawInfo.id;
    this.luckyDrawInfoModel.code = luckyDrawInfo.code;
    this.luckyDrawInfoModel.name = luckyDrawInfo.name;
    this.luckyDrawInfoModel.description = luckyDrawInfo.description;
    this.luckyDrawInfoModel.short_description = luckyDrawInfo.short_description;
    this.luckyDrawInfoModel.brevo_template_id = luckyDrawInfo.brevo_mail_template_id;
    this.luckyDrawInfoModel.start_date = luckyDrawInfo.start_date;
    this.luckyDrawInfoModel.end_date = luckyDrawInfo.end_date;
    // this.rewardInfoModel.entry_url = rewardInfo.entry_url;
    this.luckyDrawInfoModel.pos_receipt_text = luckyDrawInfo.pos_receipt_text;
    this.luckyDrawInfoModel.oo_receipt_text = luckyDrawInfo.oo_receipt_text;
    this.luckyDrawInfoModel.is_valid = luckyDrawInfo.is_valid;
    if (luckyDrawInfo.extra_info != null && luckyDrawInfo.extra_info !== undefined) {
      this.luckyDrawInfoModel.extra_info.channel = luckyDrawInfo.extra_info.channel;
      this.luckyDrawInfoModel.extra_info.customer = luckyDrawInfo.extra_info.customer;
      this.luckyDrawInfoModel.extra_info.min_order_amount = luckyDrawInfo.extra_info.min_order_amount;
    }
    this.luckyDrawInfoModel.brevo_template_id = luckyDrawInfo.brevo_template_id;
    this.luckyDrawInfoModel.tc_info= luckyDrawInfo.tc_info;
    this.luckyDrawInfoModel.privacy_policy = luckyDrawInfo.privacy_policy;
    this.luckyDrawInfoModel.is_published = luckyDrawInfo.is_published;
    this.editForm.patchValue(this.luckyDrawInfoModel);
    this.isFormDirty = false;
  }

  save(): void {
    // console.log(this.editForm.value)
    if (!this.editForm.valid) {
      this.editForm.markAllAsTouched();
      let snackBarData: SnackBarData = {
        message: "Some of fields are empty or conatains invalid data.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }

    if (!this.luckyDrawInfoModel.start_date) {
      let snackBarData: SnackBarData = {
        message: "Please choose a start date.",
        title: "Failed!!!",
        type: "error",
      };
      this.snackBarService.openSnackBar(snackBarData);
      return;
    }

    const formattedDate: string | null = this.datePipe.transform(
      this.editForm.get("start_date")?.value,
      "yyyy-MM-dd HH:mm:ss"
    );

    const formattedEndDate: string | null = this.datePipe.transform(
      this.editForm.get("end_date")?.value,
      "yyyy-MM-dd HH:mm:ss"
    );

    this.luckyDrawInfoModel.code = this.editForm.get("code")?.value;
    this.luckyDrawInfoModel.name = this.editForm.get("name")?.value;
    this.luckyDrawInfoModel.description = this.editForm.get("description")?.value;
    this.luckyDrawInfoModel.short_description = this.editForm.get("short_description")?.value;
    // this.rewardInfoModel.entry_url = this.editForm.get("entry_url")?.value;
    this.luckyDrawInfoModel.pos_receipt_text = this.editForm.get("pos_receipt_text")?.value;
    this.luckyDrawInfoModel.oo_receipt_text = this.editForm.get("oo_receipt_text")?.value;
    this.luckyDrawInfoModel.is_valid = this.editForm.get("is_valid")?.value;
    this.luckyDrawInfoModel.extra_info.channel = this.editForm.get("target_channel")?.value;
    this.luckyDrawInfoModel.extra_info.customer = this.editForm.get("target_audience")?.value;
    this.luckyDrawInfoModel.extra_info.min_order_amount = this.editForm.get("qualified_spend")?.value;
    this.luckyDrawInfoModel.start_date = formattedDate;
    this.luckyDrawInfoModel.end_date = formattedEndDate;
    this.luckyDrawInfoModel.brevo_template_id = this.editForm.get(
      "brevo_template_id"
    )?.value;
    // if (this.luckyDrawInfoModel.is_valid == 1) {
    //   if (this.luckyDrawInfoModel.start_date != this.luckyDrawInfoModel.end_date) {

    //   }
    // }
    this.luckDrawService.update(this.luckyDrawInfoModel).subscribe({
      next: (response) => {
        if (response.status == "SUCCESS") {
          this.isFormDirty = false;
          // this.buildRewardFromResponse(response);
          let snackBarData: SnackBarData = {
            type: "success",
            title: "Saved",
            message:
              "The record for " +
              this.luckyDrawInfoModel.name +
              "  " + response.message,
          };
          this.snackBarService.openSnackBar(snackBarData);
          this.router.navigate(['draw-setting']);

        }
        else {

          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };

          this.snackBarService.openSnackBar(snackBarData);

        }
      },
      error: (_error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to update. Connection to the server failed",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  saleChannels = [
    { title: 'Online', value: 1 },
    { title: 'Instore', value: 2 },
    { title: 'Both', value: 3 },
  ];

  targetAudience = [
    { title: 'Member', value: 1 },
    { title: 'Guest', value: 2 },
    { title: 'Both', value: 3 },
  ];

  // Function to handle Sales Channels checkboxes
  onSalesChannelChange($event: any): void {
    this.isFormDirty = true;
  }

  /**
   * Loads the LuckyDraw
   */
  loadProducts(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.luckDrawService.getProducts(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          // Exclude products where item_type is 3
          this.productList = response.data.items.filter(
            (product: { item_type: number }) => product.item_type !== 3
          );
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load products.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }

  openSelectionTypeDialog(triggerBy: any): void {
    const dialogRef = this.dialog.open(LuckyDrawProductBrowseDialogComponent, {
      width: "400px",
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        // this.openProductBrowseDialog(result.type, triggerBy);
      }
    });
  }

  isDisabled(): boolean {
    // Add your conditions here to determine if inputs should be disabled
    const canOverride = this.selectedSystemEvent && this.selectedSystemEvent.can_override === 0;
    const isSelectedSystemEvent = this.selectedSystemEvent !== null;

    return canOverride && isSelectedSystemEvent;
  }

  loadMailTemplateList(): void {
    let requestData = {
      search: {
        filters: {
          column: [],
          scope: { limit: 0, offset: 0 },
        },
        sort: [{ column: "name", order: "asc" }],
      },
    };

    this.luckDrawService.getMailTemplateList(requestData).subscribe({
      next: (response) => {
        if (response.status === "SUCCESS") {
          this.mailTemplateList = response.data.template_list;
        } else {
          let snackBarData: SnackBarData = {
            message: response.message,
            title: "Failed!!!",
            type: "error",
          };
          this.snackBarService.openSnackBar(snackBarData);
        }
      },
      error: (error) => {
        let snackBarData: SnackBarData = {
          message: "Failed to load mail template lists.",
          title: "Failed!!!",
          type: "error",
        };
        this.snackBarService.openSnackBar(snackBarData);
      },
    });
  }
  /**
   * Open editor dialog
   * @param params 
   */
  actionOpenEditor(params: any): void {
    if (params === 'terms') {
      this.displayTitle = 'Terms and Conditions';
      this.editorData = this.luckyDrawInfoModel.tc_info
    } else if (params === 'privacy') {
      this.displayTitle = 'Privacy Policy';
      this.editorData = this.luckyDrawInfoModel.privacy_policy;
    }
    const editorConfig = {
      'title': this.displayTitle,
      'data': this.editorData,
      'params': params,
      'infoData':this.luckyDrawInfoModel
    };
console.log(this.luckyDrawInfoModel)

    const dialogRef = this.dialog.open(HtmlEditComponent, {
      width: '80%', // Adjust the width as needed
      data: editorConfig,
    });
   dialogRef.afterClosed()
    .subscribe(result => {
      if(result == undefined){
        console.log(this.luckyDrawInfoModel)
        this.loadSavedLuckDraw(this.luckyDrawInfoModel.id)
      }
    });

  }

}

